const actions = {
  SET_COMMON_ALERT_MODAL_VISIBLE: 'SET_COMMON_ALERT_MODAL_VISIBLE',
  SET_IS_EDIT_NOTE_STATE: 'SET_IS_EDIT_NOTE_STATE',
  SET_NOTE_CREATE_SAVE_STATE: 'SET_NOTE_CREATE_SAVE_STATE',
  ROTATE_FULLSCREEN_IMAGE_ANGLE: 'ROTATE_FULLSCREEN_IMAGE_ANGLE',
  SET_FOLDER_DROP_ZONE_VISIBILITY: 'SET_FOLDER_DROP_ZONE_VISIBILITY',
  TEMP0RARY_ACTION: 'TEMP0RARY_ACTION',
  TEMP0RARY_ACTION_SUCCESS: 'TEMP0RARY_ACTION_SUCCESS',
  SHOW_CREATE_FOLDER_MODAL: 'SHOW_CREATE_FOLDER_MODAL',
  CREATE_NEW_FOLDER: 'CREATE_NEW_FOLDER',
  DROPPABLE_OVERLAY_VISIBLE: 'DROPPABLE_OVERLAY_VISIBLE',
  UPDATE_WEBSITE_PLAN_ID_TO_SUBSCRIBE: 'UPDATE_WEBSITE_PLAN_ID_TO_SUBSCRIBE',
  DOWNLOAD_PROGRESS_STATUS: 'DOWNLOAD_PROGRESS_STATUS',
  COMPLETED_PERCENTAGE: 'COMPLETED_PERCENTAGE',
  UPDATE_SPARK_HEADER_IMAGE: 'UPDATE_SPARK_HEADER_IMAGE',
  UPDATE_SPARK_PROFILE_IMAGE: 'UPDATE_SPARK_PROFILE_IMAGE',
  CREATE_NEW_FOLDER_FROM_SPARK: 'CREATE_NEW_FOLDER_FROM_SPARK',
  RESET_NEW_FOLDER_FROM_SPARK_STATE: 'RESET_NEW_FOLDER_FROM_SPARK_STATE',
  SET_LAST_SELECTED_NODE_ID: 'SET_LAST_SELECTED_NODE_ID',
  SET_MOVE_MODAL_VISIBILITY: 'SET_MOVE_MODAL_VISIBILITY',
  SET_SELECTED_MENU_ID: 'SET_SELECTED_MENU_ID',
  CREATED_FOLDER_NODE_ID: 'CREATED_FOLDER_NODE_ID',
  PLANS_COMMON_DRAWER_VISIBILITY: 'PLANS_COMMON_DRAWER_VISIBILITY',
  PLANS_COMMON_DRAWER_COMPONENTS_VISIBILITY:
    'PLANS_COMMON_DRAWER_COMPONENTS_VISIBILITY',
  PLANS_COMMON_POPUP_MODAL_VISIBILITY: 'PLANS_COMMON_POPUP_MODAL_VISIBILITY',
  UPDATE_PLANS_DRAWER_TITLE: 'UPDATE_PLANS_DRAWER_TITLE',
  MOVE_MODAL_STATE: 'MOVE_MODAL_STATE',
  CREATE_MODAL_BUTTON_DISABLE: 'CREATE_MODAL_BUTTON_DISABLE',
  SET_ACTIVE_DRAG_ID: 'SET_ACTIVE_DRAG_ID',
  SET_OVER_DRAG_ID: 'SET_OVER_DRAG_ID',
  RESET_OVER_ID: 'RESET_OVER_ID',
  DROP_ZONE_DETAILS: 'DROP_ZONE_DETAILS',
  CREATE_MODULE_TYPE: 'CREATE_MODULE_TYPE',
  ITEMS_EXPAND_COLLAPSE: 'ITEMS_EXPAND_COLLAPSE',
  CONTEXT_MENU_DETAILS: 'CONTEXT_MENU_DETAILS',
  GET_ALL_ARCHIVED_LIST: 'GET_ALL_ARCHIVED_LIST',
  GET_ALL_ARCHIVED_LIST_SUCCESS: 'GET_ALL_ARCHIVED_LIST_SUCCESS',
  DOCUMENT_EXPAND_COLLAPSE_VIEW: 'DOCUMENT_EXPAND_COLLAPSE_VIEW',
  DUPLICATE_DOCUMENT_ITEM_API: 'DUPLICATE_DOCUMENT_ITEM_API',
  INDIVIDUAL_ITEM_DELETE_API: 'INDIVIDUAL_ITEM_DELETE_API',
  ASYNC_MOVE_NODE_ITEM_TO_PROJECT: 'ASYNC_MOVE_NODE_ITEM_TO_PROJECT',
  ASYNC_MOVE_PROJECT_ITEM_TO_NODE: 'ASYNC_MOVE_PROJECT_ITEM_TO_NODE',
  ASYNC_MOVE_PROJECT_ITEM_TO_PROJECT: 'ASYNC_MOVE_PROJECT_ITEM_TO_PROJECT',
  SET_FILTER: 'SET_FILTER',
  HIGHLIGHT_ITEM: 'HIGHLIGHT_ITEM',
  TRIGGER_TARGET_ITEM_HIGHLIGHT: 'TRIGGER_TARGET_ITEM_HIGHLIGHT',
  DOCUMENT_ITEMS_EXPAND_COLLAPSE: 'DOCUMENT_ITEMS_EXPAND_COLLAPSE',
  DOCUMENT_ITEMS_MIXED_STATE_VIEW: 'DOCUMENT_ITEMS_MIXED_STATE_VIEW',
  INDIVIDUAL_ITEMS_EXPAND_COLLAPSE: 'INDIVIDUAL_ITEMS_EXPAND_COLLAPSE',
  CLOSE_DUPLICATE_MODAL: 'CLOSE_DUPLICATE_MODAL',
  DUPLICATE_MODAL: 'DUPLICATE_MODAL',
  DUPLICATION_PROGRESS: 'DUPLICATION_PROGRESS',
  RESET_DUPLICATE_MODAL_DATA: 'RESET_DUPLICATE_MODAL_DATA',
  RESET_DUPLICATION_PROGRESS: 'RESET_DUPLICATION_PROGRESS',
  SAVE_NOTE_CONTENT: 'SAVE_NOTE_CONTENT',
  UPDATE_BOARD_ITEM: 'UPDATE_BOARD_ITEM',
  AVATAR_URL: 'AVATAR_URL',
  RESET_AVATAR_URL: 'RESET_AVATAR_URL',
  AVATAR_LOADER: 'AVATAR_LOADER',
  SET_SWAP_BOARD_FLAG: 'SET_SWAP_BOARD_FLAG',
  SOCIAL_MEDIA_MODAL: 'SOCIAL_MEDIA_MODAL',
  RESET_SOCIAL_MEDIA_MODAL: 'RESET_SOCIAL_MEDIA_MODAL',
  SET_REDIRECTED_ITEM_DETAILS: 'SET_REDIRECTED_ITEM_DETAILS',
  DOCUMENT_ITEM_DELETE_API: 'DOCUMENT_ITEM_DELETE_API',
  FETCH_SHARE_URL: 'FETCH_SHARE_URL',
  FETCH_SHARE_URL_SUCCESS: 'FETCH_SHARE_URL_SUCCESS',
  FETCH_SHARE_URL_FAILURE: 'FETCH_SHARE_URL_FAILURE',
  CLOSE_DUPLICATE_NOTIFICATION: 'CLOSE_DUPLICATE_NOTIFICATION',
  ASYNC_REORDER_PROJECT_ITEM: 'ASYNC_REORDER_PROJECT_ITEM',
  ASYNC_REORDER_FOLDER_ITEM: 'ASYNC_REORDER_FOLDER_ITEM',
  GET_DOCUMENT_DETAILS: 'GET_DOCUMENT_DETAILS',
  GET_DOCUMENT_DETAILS_SUCCESS: 'GET_DOCUMENT_DETAILS_SUCCESS',
  DUPLICATE_LIST_VIEW_FULLSCREEN_DOCUMENT_ITEM:
    'DUPLICATE_LIST_VIEW_FULLSCREEN_DOCUMENT_ITEM',
  DUPLICATE_LIST_VIEW_FULLSCREEN_DOCUMENT_ITEM_SUCCESS:
    'DUPLICATE_LIST_VIEW_FULLSCREEN_DOCUMENT_ITEM_SUCCESS',
  DELETE_LIST_VIEW_FULLSCREEN_DOCUMENT_ITEM:
    'DELETE_LIST_VIEW_FULLSCREEN_DOCUMENT_ITEM',
  DELETE_LIST_VIEW_FULLSCREEN_DOCUMENT_ITEM_API:
    'DELETE_LIST_VIEW_FULLSCREEN_DOCUMENT_ITEM_API',
  ADD_LIST_VIEW_FULLSCREEN_DOCUMENT_ITEM: 'ADD_LIST_VIEW_FULLSCREEN_DOCUMENT_ITEM',
  ADD_LIST_VIEW_FULLSCREEN_DOCUMENT_ITEM_SUCCESS: 'ADD_LIST_VIEW_FULLSCREEN_DOCUMENT_ITEM_SUCCESS',
  EDIT_LIST_VIEW_FULLSCREEN_DOCUMENT_ITEMS: 'EDIT_LIST_VIEW_FULLSCREEN_DOCUMENT_ITEMS',
  UPDATE_LIST_VIEW_FULLSCREEN_DOCUMENT_DETAILS: 'UPDATE_LIST_VIEW_FULLSCREEN_DOCUMENT_DETAILS',
  RESET_DOCUMENT_DETAILS: 'RESET_DOCUMENT_DETAILS',
  UPDATE_BOARD_ITEM_PROPERTIES: 'UPDATE_BOARD_ITEM_PROPERTIES',
  UPDATE_DOCUMENT_ITEM_PROPERTIES: 'UPDATE_DOCUMENT_ITEM_PROPERTIES',
  UPDATE_LIST_VIEW_FULLSCREEN_DOCUMENT_CONTACT_ITEM_SUCCESS: 'UPDATE_LIST_VIEW_FULLSCREEN_DOCUMENT_CONTACT_ITEM_SUCCESS',
  FULLSCREEN_DOCUMENT_DELETE_MODAL: 'FULLSCREEN_DOCUMENT_DELETE_MODAL',
  RESET_FULLSCREEN_DOCUMENT_DELETE_MODAL: 'RESET_FULLSCREEN_DOCUMENT_DELETE_MODAL',
  UPDATE_ALL_ARCHIVED_LIST: 'UPDATE_ALL_ARCHIVED_LIST',
  RESET_ALL_ARCHIVED_LIST: 'RESET_ALL_ARCHIVED_LIST',
  UPDATE_ALL_ARCHIVED_LIST_ITEM: 'UPDATE_ALL_ARCHIVED_LIST_ITEM',
  URL_PREVENT_DEFAULT: 'URL_PREVENT_DEFAULT',
  EXPAND_COLLAPSE_ALL: 'EXPAND_COLLAPSE_ALL',
  EXPAND_COLLAPSE_IND: 'EXPAND_COLLAPSE_IND',
  EXPAND_COLLAPSE_MIXED: 'EXPAND_COLLAPSE_MIXED',
  REORDER_FULLSCREEN_DOCUMENT_ITEM: 'REORDER_FULLSCREEN_DOCUMENT_ITEM',
  DELETE_ALL_ARCHIVE_ITEM: 'DELETE_ALL_ARCHIVE_ITEM',
  HARD_DELETE_ALL_ARCHIVE_ITEMS: 'HARD_DELETE_ALL_ARCHIVE_ITEMS',
  UPDATE_ON_BOARDING_STEPS: 'UPDATE_ON_BOARDING_STEPS',
  ONBOARDING_TOUR: 'ONBOARDING_TOUR',
  RESET_ONBOARDING_TOUR: 'RESET_ONBOARDING_TOUR',
  PRE_CONFIG_ALERT_VISIBILITY: 'PRE_CONFIG_ALERT_VISIBILITY',
  CREATE_NEW_FOLDER_SUCCESS: 'CREATE_NEW_FOLDER_SUCCESS',
  CREATE_NEW_FOLDER_FAILURE: 'CREATE_NEW_FOLDER_FAILURE',
  ARCHIVE_PAGE: 'ARCHIVE_PAGE',
  UN_ARCHIVE_PAGE: 'UN_ARCHIVE_PAGE',
  DELETE_MULTI_SELECT: 'DELETE_MULTI_SELECT',
  FULL_SCREEN_PAGE_MULTI_SELECT: 'FULL_SCREEN_PAGE_MULTI_SELECT',
  SET_CAPTURES_NOTIFICATION_OBJECT: 'SET_CAPTURES_NOTIFICATION_OBJECT',
  SET_MY_FILES_NOTIFICATION_OBJECT: 'SET_MY_FILES_NOTIFICATION_OBJECT',
  SET_JOTS_NOTIFICATION_OBJECT: 'SET_JOTS_NOTIFICATION_OBJECT',
  DRAG_START: 'DRAG_START',
  SET_DRAG_DETAILS: 'SET_DRAG_DETAILS',
  SET_DROP_ACTION: 'SET_DROP_ACTION',
  DELETE_OBJECT: 'DELETE_OBJECT',
  ASYNC_MOVE_API: 'ASYNC_MOVE_API',
  ASYNC_MOVE_CAPTURE_ITEM_TO_OTHERS: 'ASYNC_MOVE_CAPTURE_ITEM_TO_OTHERS',
  ASYNC_MOVE_ITEMS_TO_CAPTURE: 'ASYNC_MOVE_ITEMS_TO_CAPTURE',
  ASYNC_REORDER: 'ASYNC_REORDER',
  DELETE_ASYNC_LOADER_VISIBILITY: 'DELETE_ASYNC_LOADER_VISIBILITY',
  FREE_SPACE_API_LATEST: 'FREE_SPACE_API_LATEST',
  CUT_ITEM: 'CUT_ITEM',
  CLEAR_CUT_ITEM: 'CLEAR_CUT_ITEM',
  PREVENT_API_ACTION_FROM_SEARCH: 'PREVENT_API_ACTION_FROM_SEARCH',
  SET_DROPPED_DATA: 'SET_DROPPED_DATA',
  SAVE_NOTE_TITLE: 'SAVE_NOTE_TITLE',
  SET_PROPERTIES_LOADER: 'SET_PROPERTIES_LOADER',
};

export default actions;
