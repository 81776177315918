export const CONTAINER_TYPES = {
  JOTS: 'jots',
  JOTS_ITEM: 'jotsItem',
  CAPTURE_ITEM: 'captureItem',
  JOTS_PAGE: 'jotsPage',
  JOTS_CREATE_PAGE: 'jotsCreatePage',
  CAPTURE: 'capture',
  MY_FILES: 'myFiles',
  MY_FILES_PAGE: 'myFilesPage',
  MY_FILES_CREATE_PAGE: 'myFilesCreatePage',
  FOLDER: 'folder',
  MY_FILES_CREATE_FOLDER: 'myFilesCreateFolder',
  MY_FILES_ITEM: 'myFilesItem',
  INDEX_SECTION_FOLDER: 'indexSectionFolder',
  INDEX_SECTION: 'indexSection',
  INDEX_SECTION_CATEGORY: 'indexSectionCategory',
  JOTS_FREE_SPACE_PAGE: 'jotsFreeSpacePage',
  MY_FILES_FREE_SPACE_PAGE: 'myFilesFreeSpacePage',
  MY_FILES_FREE_SPACE_FOLDER: 'myFilesFreeSpaceFolder',
  FULL_SCREEN_ITEM: 'fullScreenItem',
  FULL_SCREEN_PAGE: 'fullScreenPage',
  MY_FILES_CATEGORY: 'myFilesCategory',
  // Add other container types here
};

export const dragItemType = ['image', 'video'];

export const allowHighlightsForCaptureItem = [
  CONTAINER_TYPES.JOTS,
  'index',
  CONTAINER_TYPES.MY_FILES,
];
export const jotsContainerHighlightAccepts = [
  CONTAINER_TYPES.CAPTURE_ITEM,
  CONTAINER_TYPES.MY_FILES_ITEM,
  CONTAINER_TYPES.MY_FILES_PAGE,
  CONTAINER_TYPES.MY_FILES_FREE_SPACE_PAGE,
];
export const myFilesContainerHighlightAccepts = [
  CONTAINER_TYPES.CAPTURE_ITEM,
  CONTAINER_TYPES.JOTS_ITEM,
  CONTAINER_TYPES.JOTS_PAGE,
  CONTAINER_TYPES.MY_FILES_FREE_SPACE_PAGE,
];
export const captureContainerHighlightAccepts = [
  CONTAINER_TYPES.JOTS_ITEM,
  CONTAINER_TYPES.MY_FILES_ITEM,
];
export const DND_CAPTURE_ITEM_DROP_ON_JOTS_DOCUMENT_CREATION_CONTAINER =
  'capture-item-drop-on-jots-document-creation-flow';
export const DND_JOTS_ITEM_DROP_ON_JOTS_DOCUMENT_CREATION_CONTAINER =
  'jots-item-drop-on-jots-document-creation-flow';
export const DND_CAPTURE_ITEM_DROP_ON_MY_FILES_CREATION_CONTAINER =
  'capture-item-drop-on-my-files-creation-flow';
export const DND_MY_FILES_ITEM_DROP_ON_MY_FILES_CREATION_CONTAINER =
  'my-files-item-drop-on-my-files-creation-flow';
export const DND_MY_FILES_PAGE_DROP_ON_MY_FILES_CREATION_CONTAINER =
  'my-files-page-drop-on-my-files-creation-flow';
export const DND_MY_FILES_FOLDER_DROP_ON_MY_FILES_CREATION_CONTAINER =
  'my-files-folder-drop-on-my-files-creation-flow';
export const DND_JOTS_ITEM_DROP_ON_MY_FILES_CREATION_CREATION_CONTAINER =
  'jots-item-drop-on-my-files-creation-flow';
export const DND_JOTS_PAGE_DROP_ON_MY_FILES_CREATION_CONTAINER =
  'jots-page-drop-on-my-files-creation-flow';
export const DND_MY_FILES_ITEM_DROP_ON_JOTS_CREATION_CONTAINER =
  'my-files-item-drop-on-jots-creation-flow';
export const documentCreationContainerAccepts = [
  CONTAINER_TYPES.JOTS,
  CONTAINER_TYPES.JOTS_ITEM,
  CONTAINER_TYPES.MY_FILES,
  CONTAINER_TYPES.MY_FILES_ITEM,
  CONTAINER_TYPES.CAPTURE,
  CONTAINER_TYPES.CAPTURE_ITEM,
];
export const JOTS_PAGE_CREATE_ACTIONS = [
  DND_CAPTURE_ITEM_DROP_ON_JOTS_DOCUMENT_CREATION_CONTAINER,
  DND_JOTS_ITEM_DROP_ON_JOTS_DOCUMENT_CREATION_CONTAINER,
  DND_MY_FILES_ITEM_DROP_ON_JOTS_CREATION_CONTAINER,
];

export const RESTRICT_MY_FILES_DROPPABLE_IN_REORDER = [
  CONTAINER_TYPES.MY_FILES_ITEM,
  CONTAINER_TYPES.MY_FILES_PAGE,
  CONTAINER_TYPES.FOLDER,
];

export const RESTRICT_JOTS_DROPPABLE_CONTAINER = [
  CONTAINER_TYPES.JOTS_ITEM,
  CONTAINER_TYPES.JOTS_PAGE,
];

export const DEFAULT_ITEM_PROPERTIES = {
  OFFSET_X: 40,
  OFFSET_Y: 40,
  MAX_Y: 800,
};
