import React from 'react';
import i18n from '../../i18n';
import { store } from 'redux/store';
import alertSuccessIcon from 'images/svgs/latest-V2/iconSuccess.svg';
import alertCloseIcon from 'images/svgs/latest-V2/iconCloseAlert.svg';
import alertFailureIcon from 'images/svgs/latest-V2/iconFailure.svg';
import alertInfoIcon from 'images/svgs/latest-V2/iconInfo.svg';
import alertWarningIcon from 'images/svgs/latest-V2/iconWarning.svg';
import { randomIdGenerate } from '../../utility/helpers';
import { SuccessAlertMessage } from './alertViewHelper';

const alertView = ({
  alertText,
  type = 'success',
  delay,
  closeable = true,
  data,
  idData,
  deleteMessage = false,
  nodeId,
  groupId,
  itemId,
  projectId,
  nodeType,
  sharedSparkNodeType,
  captureData,
  itemIds,
  itemType,
  currentHierarchy,
  isGroup = false,
  highlightId,
  isArchiveContext = false,
  isFromTrash = false,
  sharedLink = null,
  isShareIndividualItem = false,
  isAllArchiveItems = false,
  extractName = {},
  sharingData,
  parentId,
  preventShareRedirection = true,
  isFromSparkContextMenu = false,
}) => {
  const { currentTab } = store.getState().Settings;
  const { onboardingState } = store.getState().Auth;
  const { jotsNotification } = store.getState().commonReducer;

  if (type === 'info') {
    const notificationKey = randomIdGenerate('info-alert');
    return jotsNotification.open({
      key: notificationKey,
      message: (
        <div className={'content'}>
          <img src={alertInfoIcon} alt={'plusIcon'} />
          <span>{i18n.t(alertText, { data })}</span>
        </div>
      ),
      closeIcon: closeable ? (
        <img src={alertCloseIcon} alt={'plusIcon'} />
      ) : (
        <></>
      ),
      className: 'custom-alert info',
      placement: 'bottom',
      duration: delay || 3,
      onClose() {
        jotsNotification.destroy(notificationKey);
      },
    });
  } else if (type === 'success') {
    const notificationKey = randomIdGenerate('success-alert');
    return jotsNotification.open({
      key: notificationKey,
      message: (
        <div className={'content'}>
          <img src={alertSuccessIcon} alt={'plusIcon'} />
          <div className={'main-message'}>
            <SuccessAlertMessage
              notificationAPI={jotsNotification}
              alertText={alertText}
              idData={idData}
              nodeId={nodeId}
              itemId={itemId}
              projectId={projectId}
              isFromTrash={isFromTrash}
              currentTab={currentTab}
              highlightId={highlightId}
              currentHierarchy={currentHierarchy}
              isGroup={isGroup}
              nodeType={nodeType}
              groupId={groupId}
              parentId={parentId}
              itemType={itemType}
              deleteMessage={deleteMessage}
              isArchiveContext={isArchiveContext}
              sharedSparkNodeType={sharedSparkNodeType}
              itemIds={itemIds}
              captureData={captureData}
              isAllArchiveItems={isAllArchiveItems}
              notificationKey={notificationKey}
              preventShareRedirection={preventShareRedirection}
              isFromSparkContextMenu={isFromSparkContextMenu}
              sharedLink={sharedLink}
              isShareIndividualItem={isShareIndividualItem}
              onboardingState={onboardingState}
              sharingData={sharingData}
              extractName={extractName}
              data={data}
              directParent={false}
            />
          </div>
        </div>
      ),
      closeIcon: closeable ? (
        <img src={alertCloseIcon} alt={'plusIcon'} />
      ) : (
        <></>
      ),
      onClose() {
        jotsNotification.destroy(notificationKey);
      },
      className: 'custom-alert success',
      placement: 'bottom',
      duration: delay || 3,
    });
  } else if (type === 'warning') {
    const notificationKey = randomIdGenerate('warning-alert');
    return jotsNotification.open({
      key: notificationKey,
      message: (
        <div className={'content'}>
          <img src={alertWarningIcon} alt={'plusIcon'} />
          <span>{i18n.t(alertText, { data })}</span>
        </div>
      ),
      closeIcon: closeable ? (
        <img src={alertCloseIcon} alt={'plusIcon'} />
      ) : (
        <></>
      ),
      className: 'custom-alert warning',
      placement: 'bottom',
      duration: delay || 3,
      onClose() {
        jotsNotification.destroy(notificationKey);
      },
    });
  } else if (type === 'error') {
    const notificationKey = randomIdGenerate('error-alert');
    return jotsNotification.open({
      key: notificationKey,
      message: (
        <div className={'content'}>
          <img src={alertFailureIcon} alt={'plusIcon'} />
          <span>{i18n.t(alertText, { data })}</span>
        </div>
      ),
      onClose() {
        jotsNotification.destroy(notificationKey);
      },
      closeIcon: closeable ? (
        <img src={alertCloseIcon} alt={'plusIcon'} />
      ) : (
        <></>
      ),
      className: 'custom-alert failure',
      placement: 'bottom',
      duration: delay || 3,
    });
  }
  const defaultNotificationKey = randomIdGenerate('default-alert');
  return jotsNotification.open({
    key: defaultNotificationKey,
    message: i18n.t(alertText),
    className: 'custom-alert success',
    placement: 'bottom',
    duration: delay || 3,
    onClose() {
      jotsNotification.destroy(defaultNotificationKey);
    },
  });
};

export default alertView;
