import { store } from 'redux/store';
import authActions from 'redux/auth/actions';
import AuthAction from 'redux/auth/actions';
import jotsActions from 'redux/jots/actions';
import captureActions from 'redux/capture/actions';
import myFilesActions from 'redux/boardGroups/actions';
import boardGroupActions from 'redux/boardGroups/actions';
import peopleActions from 'redux/people/actions';
import shareActions from 'redux/sharedSparks/actions';
import trashActions from 'redux/trash/actions';
import commonActions from 'redux/common/actions';
import { alertView, showInfoModal } from '../helpers';
import { indexSectionDelete } from 'components/files/helper';

export function jotsHelper({ parentId }) {
  const activeTabs = store.getState().Settings.currentTab || [];
  if (activeTabs.includes('home')) {
    const { jotsProjectItems } = store.getState().jotsReducer || [];
    const { jots: jotsSortFilter = 'newest_first' } =
      store.getState().commonReducer?.sortByFilter || {};
    if (jotsProjectItems.length === 0 || jotsSortFilter === 'newest_first') {
      store.dispatch({
        type: jotsActions.GET_HOME_PROJECT_DETAILS,
        isFromPusher: true,
      });
    } else {
      store.dispatch({
        type: jotsActions.GET_JOTS_PAGINATION_LIST,
        isFromPusher: true,
        payload: {
          lastId: jotsProjectItems[jotsProjectItems.length - 1]?.id,
        },
      });
    }
  }
}

export function capturesHelper() {
  const activeTabs = store.getState().Settings.currentTab || [];

  if (activeTabs.includes('capture')) {
    store.dispatch({
      type: captureActions.GET_CAPTURE_LISTS,
      isFromPusher: true,
    });
  }
}

export function categoryHelper({ parentId }) {
  const activeTabs = store.getState().Settings.currentTab || [];
  if (activeTabs.includes('activities')) {
    store.dispatch({
      type: myFilesActions.GET_BOARD_GROUPS_LIST,
      isFromPusher: true,
    });
    store.dispatch({
      type: myFilesActions.GET_ALL_GROUPS_LIST,
      isFromPusher: true,
    });
  }
}

export function pusherMoveHelper({ from, to }) {
  const activeTabs = store.getState().Settings.currentTab || [];
  const jotsProjectDetails =
    store.getState().jotsReducer.jotsProjectDetails || [];
  const currentId = store.getState().BoardGroups.currentId || null;
  if (activeTabs.includes('capture') && (!from || !to)) {
    store.dispatch({
      type: captureActions.GET_CAPTURE_LISTS,
      isFromPusher: true,
    });
  }
  if (
    activeTabs.includes('home') &&
    (jotsProjectDetails?.id === from || jotsProjectDetails?.id === to)
  ) {
    store.dispatch({
      type: jotsActions.GET_HOME_PROJECT_DETAILS,
      isFromPusher: true,
    });
  }
  if (
    activeTabs.includes('activities') &&
    ((from && from === currentId) || (to && to === currentId))
  ) {
    store.dispatch({
      type: boardGroupActions.GET_FOLDER_DETAILS,
      isFromPusher: true,
    });
  }
}

export function commonTypeHelper({
  state = '',
  parentId = null,
  objectIds = [],
  isCaptureItem = false,
  isSignature = false,
  from = null,
  to = null,
}) {
  const activeTabs = store.getState().Settings.currentTab || [];
  if (state === 'moved') {
    pusherMoveHelper({ from, to });
  } else if (state === 'deleted') {
    commonDeleteHelper({
      parentId,
      objectIds,
      isCaptureItem,
      isSignature,
      activeTabs,
    });
  } else if (activeTabs?.includes('activities')) {
    const { boardDetails, currentId } = store.getState().BoardGroups || [];
    const { [currentId]: myFilesSortFilter = 'newest_first' } =
      store.getState().commonReducer?.sortByFilter || {};
    if (parentId === currentId) {
      if (boardDetails.length === 0 || myFilesSortFilter === 'newest_first') {
        store.dispatch({
          type: boardGroupActions.GET_FOLDER_DETAILS,
          isFromPusher: true,
        });
      } else {
        store.dispatch({
          type: boardGroupActions.GET_MY_FILES_PAGINATION_LIST,
          isFromPusher: true,
          payload: {
            lastId: boardDetails[boardDetails.length - 1]?.id,
          },
          id: parentId,
        });
      }
    }
  }
}

export function peoplesHelper(data) {
  const activeTabs = store.getState().Settings.currentTab || [];
  if (activeTabs.includes('people')) {
    store.dispatch({
      type: peopleActions.GET_SIGNATURE_LIST,
      isFromPusher: true,
    });
  }
}

export function trashHelper({ state = '' }) {
  const activeTabs = store.getState().Settings.currentTab || [];
  if (activeTabs.includes('trash')) {
    store.dispatch({
      type: trashActions.GET_TRASH_LIST,
      isFromPusher: true,
    });
  } else if (state === 'recovered') {
    /* TODO:
        1. While recovering the deleted item, we are initiating the initial GET API call as per the below conditions.
        2. It should be revamped in future to avoid data loss for active users working on the same screen.
    */
    if (activeTabs.includes('home')) {
      store.dispatch({
        type: jotsActions.GET_HOME_PROJECT_DETAILS,
        isFromPusher: true,
      });
    }
    if (activeTabs.includes('capture')) {
      store.dispatch({
        type: captureActions.GET_CAPTURE_LISTS,
        isFromPusher: true,
      });
    }
    if (activeTabs.includes('activities')) {
      const currentId = store.getState().BoardGroups.currentId || null;
      if (currentId === 'groups') {
        store.dispatch({
          type: boardGroupActions.GET_BOARD_GROUPS_LIST,
          isFromPusher: true,
        });
        store.dispatch({
          type: myFilesActions.GET_ALL_GROUPS_LIST,
          isFromPusher: true,
        });
      } else {
        store.dispatch({
          type: boardGroupActions.GET_FOLDER_DETAILS,
          isFromPusher: true,
        });
      }
    }
  }
}

export function shareHelper({ state = '', hierarchyId = null }) {
  const activeTabs = store.getState().Settings.currentTab || [];
  if (activeTabs.includes('shared')) {
    const sharedData = store.getState().SharedSparks.listSharedData || [];
    if (state === 'deleted') {
      store.dispatch({
        type: shareActions.DELETE_LATEST_SHARED_SPARKS_SUCCESS,
        id: hierarchyId,
      });
    } else if (sharedData.length === 0) {
      store.dispatch({
        type: shareActions.GET_SHARED_LIST,
        isFromPusher: true,
      });
    } else {
      store.dispatch({
        type: shareActions.GET_SHARED_PAGINATION_LIST,
        isFromPusher: true,
        payload: {
          lastId: sharedData[sharedData.length - 1].id,
        },
      });
    }
  }
}

export function hierarchyTypeHelper({
  state = '',
  parentId = null,
  module = [],
  isCaptureItem = false,
  isSignature = false,
}) {
  const activeTabs = store.getState().Settings.currentTab || [];
  module.forEach((mod) => {
    if (activeTabs.includes(mod)) {
      if (mod === 'jots') {
        jotsHelper({ parentId });
      } else if (mod === 'captures') {
        capturesHelper();
      } else if (mod === 'myFiles') {
        commonTypeHelper({ state, parentId, isCaptureItem, isSignature });
      }
    }
  });
}

export const updateUserInfo = (response = []) => {
  store.dispatch({
    type: authActions.GET_USER_DETAILS_SUCCESS,
    payload: response,
    isFromPusher: true,
  });
  if (response.deactivated) {
    store.dispatch({ type: AuthAction.LOGOUT_USER });
  }
};

export const endSession = (response = []) => {
  const activeSessions = store.getState().Auth.activeSessions;
  let currentSession = activeSessions?.sessions.filter(
    (active) => active?.is_current_device,
  );
  if (
    currentSession.length > 0 &&
    !response?.active_sessions.includes(currentSession[0].id)
  ) {
    showInfoModal('signed_out', 'end_session_desc', 'OK');
  }
};

function commonDeleteHelper({
  parentId,
  objectIds,
  isCaptureItem,
  isSignature,
  activeTabs,
}) {
  const jotsProjectDetails =
    store.getState().jotsReducer.jotsProjectDetails || [];
  const currentId = store.getState().BoardGroups.currentId || null;


  // Peoples Tab Delete
  if (isSignature && activeTabs.includes('people')) {
    store.dispatch({
      type: peopleActions.DELETE_SIGNATURE_VIA_PUSHER,
      payload: objectIds[0],
    });
  }
  // Capture Delete
  else if (isCaptureItem && activeTabs.includes('capture')) {
    store.dispatch({
      type: captureActions.DELETE_CAPTURE_SUCCESS,
      payload: objectIds,
    });
  }
  // Jots Delete
  else if (
    activeTabs.includes('home') &&
    parentId &&
    jotsProjectDetails?.id === parentId
  ) {
    store.dispatch({
      type: jotsActions.DELETE_JOTS_ITEM_VIA_PUSHER,
      payload: {
        object_ids: objectIds,
      },
    });
  }
  // MyFiles Delete
  else if (activeTabs.includes('activities')) {
    // Category Delete
    if (!parentId && !isCaptureItem) {
      store.dispatch({
        type: boardGroupActions.UPDATE_DELETE_GROUP,
        deleteGroupId: objectIds[0],
      });
    }
    // My Files Folders and Inside Folders Delete
    else if (currentId === parentId) {
      store.dispatch({
        type: boardGroupActions.DELETE_MY_FILES_ITEM_VIA_PUSHER,
        payload: {
          object_ids: objectIds,
        },
      });
    }
    // Index Section Delete
    else {
      const allGroupList = store.getState().BoardGroups.allGroupList || [];
      indexSectionDelete(allGroupList, objectIds || []);
    }
  }
}

export function duplicateHelper(data) {
  store.dispatch({
    type: commonActions.DUPLICATION_PROGRESS,
    payload: data,
  });
}

export function duplicationAlertHelper({ itemType = 'page' }) {
  let alertText = 'document_duplicated_successfully';
  switch (itemType) {
    case 'category': {
      alertText = 'group_duplicated_successfully';
      break;
    }
    case 'page': {
      alertText = 'document_duplicated_successfully';
      break;
    }
    case 'folder': {
      alertText = 'folder_duplicated_successfully';
      break;
    }
    default: {
      alertText = 'document_duplicated_successfully';
      break;
    }
  }
  alertView({
    parentContainer: 'direct',
    alertText: alertText,
    type: 'success',
  });
}

export function storageHelper(data) {
  setTimeout(()=> {
    store.dispatch({
      type: authActions.UPDATE_USED_STORAGE,
      payload: data?.storageSize,
    });
  }, 2000)
}