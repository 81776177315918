const actions = {
  GET_BOARD_GROUPS_LIST: 'GET_BOARD_GROUPS_LIST',
  GET_BOARD_GROUPS_LIST_SUCCESS: 'GET_BOARD_GROUPS_LIST_SUCCESS',
  CREATE_BOARD_GROUP_FAILURE: 'CREATE_BOARD_GROUP_FAILURE',
  CREATE_BOARD_GROUP: 'CREATE_BOARD_GROUP',
  SELECTED_KEY: 'SELECTED_KEY',
  DELETE_DOCUMENT_ITEM: 'DELETE_DOCUMENT_ITEM',
  GET_FOLDER_DETAILS_SUCCESS: 'GET_FOLDER_DETAILS_SUCCESS',
  SELECTED_PROJECT_ID: 'SELECTED_PROJECT_ID',
  GET_FOLDER_DETAILS: 'GET_FOLDER_DETAILS',
  CHANGE_BOARDS_GROUP_VIEW: 'CHANGE_BOARDS_GROUP_VIEW',
  CREATE_BOARD_GROUP_SUCCESS: 'CREATE_BOARD_GROUP_SUCCESS',
  GET_ALL_GROUPS_LIST: 'GET_ALL_GROUPS_LIST',
  GET_ALL_GROUPS_LIST_SUCCESS: 'GET_ALL_GROUPS_LIST_SUCCESS',
  UPDATE_ALL_GROUPS_LIST: 'UPDATE_ALL_GROUPS_LIST',
  GET_ALL_GROUPS_LIST_FAILURE: 'GET_ALL_GROUPS_LIST_FAILURE',
  SET_HIERARCHY_LEVEL: 'SET_HIERARCHY_LEVEL',
  ADD_NEW_BOARD_GROUP_MODAL: 'ADD_NEW_BOARD_GROUP_MODAL',
  GET_GROUP_DETAILS: 'GET_GROUP_DETAILS',
  GET_GROUP_DETAILS_SUCCESS: 'GET_GROUP_DETAILS_SUCCESS',
  SET_CURRENT_MENU_ITEM: 'SET_CURRENT_MENU_ITEM',
  SET_MENU_OPEN_KEYS: 'SET_MENU_OPEN_KEYS',
  IS_FOLDER_MENU: 'IS_FOLDER_MENU',
  SET_HIERARCHY_DETAILS: 'SET_HIERARCHY_DETAILS',
  OPEN_KEYS: 'OPEN_KEYS',
  RESET_CURRENT_TYPE: 'RESET_CURRENT_TYPE',
  UPDATE_GROUP_DETAILS: 'UPDATE_GROUP_DETAILS',
  LIST_VIEW_UPDATE_GROUP_DETAILS: 'LIST_VIEW_UPDATE_GROUP_DETAILS',
  UPDATE_BOARD_DETAILS: 'UPDATE_BOARD_DETAILS',
  UPDATE_DELETE_GROUP: 'UPDATE_DELETE_GROUP',
  DELETE_GROUP: 'DELETE_GROUP',
  UPDATE_ARCHIVE_GROUP: 'UPDATE_ARCHIVE_GROUP',
  SHOW_GROUP_BOARD_PROPERTY_MODAL: 'SHOW_GROUP_BOARD_PROPERTY_MODAL',
  UPDATE_BOARD_GROUP_PROPERTIES: 'UPDATE_BOARD_GROUP_PROPERTIES',
  UPDATE_BOARD_GROUP: 'UPDATE_BOARD_GROUP',
  UPDATE_ARCHIVE_BOARD: 'UPDATE_ARCHIVE_BOARD',
  UPDATE_DELETE_BOARD: 'UPDATE_DELETE_BOARD',
  UPDATE_DOCUMENT_ITEMS: 'UPDATE_DOCUMENT_ITEMS',
  UPDATE_DOCUMENT_ITEMS_MULTIPLE_DND: 'UPDATE_DOCUMENT_ITEMS_MULTIPLE_DND',
  ASYNC_MOVE_CAPTURE_ITEM_TO_BOARD: 'ASYNC_MOVE_CAPTURE_ITEM_TO_BOARD',
  CLOSE_PROPERTY_MODAL: 'CLOSE_PROPERTY_MODAL',
  SHOW_PROPERTY_MODAL: 'SHOW_PROPERTY_MODAL',
  UPDATE_GROUP_FOLDER_PROPERTIES: 'UPDATE_GROUP_FOLDER_PROPERTIES',
  UPDATE_GROUP_DOCUMENT_PROPERTIES: 'UPDATE_GROUP_DOCUMENT_PROPERTIES',
  UPDATE_FOLDER: 'UPDATE_FOLDER',
  UPDATE_ARCHIVE_FOLDER: 'UPDATE_ARCHIVE_FOLDER',
  UPDATE_UNARCHIVE_FOLDER: 'UPDATE_UNARCHIVE_FOLDER',
  ARCHIVE_FOLDER: 'ARCHIVE_FOLDER',
  UPDATE_DELETE_FOLDER: 'UPDATE_DELETE_FOLDER',
  HARD_DELETE_FOLDER: 'HARD_DELETE_FOLDER',
  DELETE_FOLDER: 'DELETE_FOLDER',
  REMOVE_DRAG_ITEM_FROM_DOCUMENT: 'REMOVE_DRAG_ITEM_FROM_DOCUMENT',
  REMOVE_INDIVIDUAL_DRAG_ITEM: 'REMOVE_INDIVIDUAL_DRAG_ITEM',
  UPDATE_INDIVIDUAL_ITEMS: 'UPDATE_INDIVIDUAL_ITEMS',
  UPDATE_INDIVIDUAL_ITEMS_MULTIPLE_DND: 'UPDATE_INDIVIDUAL_ITEMS_MULTIPLE_DND',
  ASYNC_MOVE_NODE_TO_NODE: 'ASYNC_MOVE_NODE_TO_NODE',
  UPDATE_DOCUMENT_LIST: 'UPDATE_DOCUMENT_LIST',
  UPDATE_MYFILES_DOCUMENT_LIST: 'UPDATE_MYFILES_DOCUMENT_LIST',
  ASYNC_MOVE_NODE_TO_BOARD: 'ASYNC_MOVE_NODE_TO_BOARD',
  UPDATE_FOLDERS_LIST: 'UPDATE_FOLDERS_LIST',
  GET_ARCHIVED_BOARD_GROUPS_LIST_SUCCESS:
    'GET_ARCHIVED_BOARD_GROUPS_LIST_SUCCESS',
  UPDATE_UNARCHIVE_GROUP: 'UPDATE_UNARCHIVE_GROUP',
  UPDATE_ARCHIVED_DELETE_GROUP: 'UPDATE_ARCHIVED_DELETE_GROUP',
  UPDATE_UNARCHIVE_BOARD: 'UPDATE_UNARCHIVE_BOARD',
  ARCHIVE_NODE: 'ARCHIVE_NODE',
  UNARCHIVE_DOCUMENT: 'UNARCHIVE_DOCUMENT',
  DELETE_DOCUMENT: 'DELETE_DOCUMENT',
  HARD_DELETE_DOCUMENT: 'HARD_DELETE_DOCUMENT',
  CLOUD_AREA_COMMON_LOADER: 'CLOUD_AREA_COMMON_LOADER',
  SET_DOCUMENT_VIEW: 'SET_DOCUMENT_VIEW',
  TOGGLE_HEADER_EXPAND_COLLAPSE: 'TOGGLE_HEADER_EXPAND_COLLAPSE',
  DUPLICATE_GROUP: 'DUPLICATE_GROUP',
  DUPLICATE_GROUPS_NODE_OR_SPARK: 'DUPLICATE_GROUPS_NODE_OR_SPARK',
  DUPLICATE_BOARD: 'DUPLICATE_BOARD',
  DUPLICATE_GROUPS_FOLDER: 'DUPLICATE_GROUPS_FOLDER',
  DUPLICATE_GROUPS_INDIVIDUAL_ITEM_API: 'DUPLICATE_GROUPS_INDIVIDUAL_ITEM_API',
  UNARCHIVE_FOLDER: 'UNARCHIVE_FOLDER',
  UNARCHIVE_DOCUMENT_API: 'UNARCHIVE_DOCUMENT_API',
  ADD_DOCUMENT_ITEM_FROM_ADD_BAR: 'ADD_DOCUMENT_ITEM_FROM_ADD_BAR',
  ADD_DOCUMENT_ITEM_SUCCESS: 'ADD_DOCUMENT_ITEM_SUCCESS',
  ADD_INDIVIDUAL_ITEM_FROM_ADD_BAR_TO_CLOUD_AREA:
    'ADD_INDIVIDUAL_ITEM_FROM_ADD_BAR_TO_CLOUD_AREA',
  ADD_INDIVIDUAL_ITEM_FROM_ADD_BAR_TO_CLOUD_AREA_SUCCESS:
    'ADD_INDIVIDUAL_ITEM_FROM_ADD_BAR_TO_CLOUD_AREA_SUCCESS',
  EDIT_DOCUMENT_ITEMS_FROM_CONTEXT_MENU:
    'EDIT_DOCUMENT_ITEMS_FROM_CONTEXT_MENU',
  EDIT_FOLDER_ITEMS_FROM_CONTEXT_MENU: 'EDIT_FOLDER_ITEMS_FROM_CONTEXT_MENU',
  DUPLICATE_GROUP_API: 'DUPLICATE_GROUP_API',
  DUPLICATE_GROUP_API_SUCCESS: 'DUPLICATE_GROUP_API_SUCCESS',
  DUPLICATE_FOLDER_API: 'DUPLICATE_FOLDER_API',
  DUPLICATE_FOLDER_API_SUCCESS: 'DUPLICATE_FOLDER_API_SUCCESS',
  DUPLICATE_DOCUMENT_ITEM_API_SUCCESS: 'DUPLICATE_DOCUMENT_ITEM_API_SUCCESS',
  DUPLICATE_FOLDER_ITEM_API: 'DUPLICATE_FOLDER_ITEM_API',
  DUPLICATE_GROUPS_INDIVIDUAL_ITEM_API_SUCCESS: 'DUPLICATE_GROUPS_INDIVIDUAL_ITEM_API_SUCCESS',
  SET_BOARDS_DOCUMENT_VIEW: 'SET_BOARDS_DOCUMENT_VIEW',
  LIST_VIEW_UPDATE_ALL_GROUPS_LIST: 'LIST_VIEW_UPDATE_ALL_GROUPS_LIST',
  DELETE_INDIVIDUAL_ITEMS: 'DELETE_INDIVIDUAL_ITEMS',
  DISABLE_MODULE_IN_HIERARCHY_STRUCTURE:
    'DISABLE_MODULE_IN_HIERARCHY_STRUCTURE',
  GET_DRAG_NODE_DETAILS: 'GET_DRAG_NODE_DETAILS',
  MOVE_NODE_INTO_CREATED_NODE: 'MOVE_NODE_INTO_CREATED_NODE',
  MOVE_DOCUMENT_INTO_CREATED_NODE: 'MOVE_DOCUMENT_INTO_CREATED_NODE',
  FREE_SPACE_COORDINATES: 'FREE_SPACE_COORDINATES',
  TOGGLE_REORDER: 'TOGGLE_REORDER',
  REORDER_GROUP_NODES: 'REORDER_GROUP_NODES',
  EDIT_INDIVIDUAL_ITEM_FROM_CONTEXT_MENU: 'EDIT_INDIVIDUAL_ITEM_FROM_CONTEXT_MENU',
  UPDATE_BOARD_GROUP_AVATAR: 'UPDATE_BOARD_GROUP_AVATAR',
  UPDATE_SELECTED_PROJECT_LIST_DOWNGRADE_PLAN: 'UPDATE_SELECTED_PROJECT_LIST_DOWNGRADE_PLAN',
  HANDLE_ON_BOARDING_NODES: 'HANDLE_ON_BOARDING_NODES',
  GET_ON_BOARDING_LIST: 'GET_ON_BOARDING_LIST',
  GET_ON_BOARDING_LIST_SUCCESS: 'GET_ON_BOARDING_LIST_SUCCESS',
  GET_ON_BOARDING_LIST_FAILURE: 'GET_ON_BOARDING_LIST_FAILURE',
  UPDATE_FOLDER_AVATAR: 'UPDATE_FOLDER_AVATAR',
  UPDATE_SELECTED_ITEM: 'UPDATE_SELECTED_ITEM',
  UPDATE_MULTIPLE_ITEM_DELETE: 'UPDATE_MULTIPLE_ITEM_DELETE',
  UPDATE_DELETE_ITEM_MODAL: 'UPDATE_DELETE_ITEM_MODAL',
  RESET_HIERARCHY_DETAILS: 'RESET_HIERARCHY_DETAILS',
  UPDATE_SELECTED_DOCUMENT_ITEM: 'UPDATE_SELECTED_DOCUMENT_ITEM',
  UPDATE_MULTIPLE_DOCUMENT_ITEM_DELETE: 'UPDATE_MULTIPLE_DOCUMENT_ITEM_DELETE',
  DUPLICATE_FOLDER_INDIVIDUAL_ITEM: 'DUPLICATE_FOLDER_INDIVIDUAL_ITEM',
  REORDER_GROUP_PROJECT_ITEM: 'REORDER_GROUP_PROJECT_ITEM',
  REORDER_GROUP_DOCUMENT_ITEM: 'REORDER_GROUP_DOCUMENT_ITEM',
  FILES_DELETE_MODAL: 'FILES_DELETE_MODAL',
  RESET_FILES_DELETE_MODAL: 'RESET_FILES_DELETE_MODAL',
  UPDATE_MY_FILES_CONTACT_ITEM_SUCCESS: 'UPDATE_MY_FILES_CONTACT_ITEM_SUCCESS',
  UPDATE_MY_FILES_DOCUMENT_CONTACT_ITEM_SUCCESS: 'UPDATE_MY_FILES_DOCUMENT_CONTACT_ITEM_SUCCESS',
  REORDER_GROUPS: 'REORDER_GROUPS',
  ASYNC_REORDER_GROUP: 'ASYNC_REORDER_GROUP',
  UPDATE_FILES_COUNT: 'UPDATE_FILES_COUNT',
  UPDATE_ALL_ARCHIVE_DELETE_ITEMS: 'UPDATE_ALL_ARCHIVE_DELETE_ITEMS',
  HANDLE_QUIT_CONFIG: 'HANDLE_QUIT_CONFIG',
  TOUR_UPDATE_ALL_GROUP_LIST_WITH_DELAY:
    'TOUR_UPDATE_ALL_GROUP_LIST_WITH_DELAY',
  UPDATE_GROUP_LIST_FROM_INSTANT_FOLDERS:
    'UPDATE_GROUP_LIST_FROM_INSTANT_FOLDERS',
  UPDATE_ALL_GROUP_LIST_FROM_INSTANT_FOLDERS: 'UPDATE_ALL_GROUP_LIST_FROM_INSTANT_FOLDERS',
  UPDATE_ONBOARDING_LIST_CHECKBOX_STATUS: 'UPDATE_ONBOARDING_LIST_CHECKBOX_STATUS',
  RESET_ON_BOARDING_LIST: 'RESET_ON_BOARDING_LIST',
  SKIP_INSTANT_FOLDERS_SELECTED_CATEGORIES: 'SKIP_INSTANT_FOLDERS_SELECTED_CATEGORIES',
  FOLDER_CHECKBOX_VALUE: 'FOLDER_CHECKBOX_VALUE',
  TOGGLE_NODE_GRID_SIZE: 'TOGGLE_NODE_GRID_SIZE',
  GET_MY_FILES_PAGE_ITEMS: 'GET_MY_FILES_PAGE_ITEMS',
  GET_MY_FILES_PAGE_ITEMS_SUCCESS: 'GET_MY_FILES_PAGE_ITEMS_SUCCESS',
  GET_MY_FILES_PAGINATION_LIST: 'GET_MY_FILES_PAGINATION_LIST',
  GET_MY_FILES_PAGINATION_LIST_SUCCESS: 'GET_MY_FILES_PAGINATION_LIST_SUCCESS',
  GET_MY_FILES_PAGINATION_LIST_FAILURE: 'GET_MY_FILES_PAGINATION_LIST_FAILURE',
  MY_FILES_MULTI_SELECT: 'MY_FILES_MULTI_SELECT',
  RESET_MY_FILES_MULTI_SELECT: 'RESET_MY_FILES_MULTI_SELECT',
  DELETE_MY_FILES_ITEM: 'DELETE_MY_FILES_ITEM',
  GET_MY_FILES_ARCHIVED_LIST: 'GET_MY_FILES_ARCHIVED_LIST',
  GET_MY_FILES_ARCHIVED_LIST_SUCCESS: 'GET_MY_FILES_ARCHIVED_LIST_SUCCESS',
  RESET_MY_FILES_ARCHIVED_LIST: 'RESET_MY_FILES_ARCHIVED_LIST',
  MY_FILES_UN_ARCHIVE_NODE: 'MY_FILES_UN_ARCHIVE_NODE',
  MY_FILES_DELETE_ARCHIVED_NODE: 'MY_FILES_DELETE_ARCHIVED_NODE',
  GET_CATEGORY_ARCHIVED_LIST: 'GET_CATEGORY_ARCHIVED_LIST',
  GET_CATEGORY_ARCHIVED_LIST_SUCCESS: 'GET_CATEGORY_ARCHIVED_LIST_SUCCESS',
  DELETE_MY_FILES_DOCUMENT_MULTIPLE_ITEMS: 'DELETE_MY_FILES_DOCUMENT_MULTIPLE_ITEMS',
  SET_MY_FILES_FREE_SPACE_GRID_SIZE: 'SET_MY_FILES_FREE_SPACE_GRID_SIZE',
  // TODO: Ticket-SP1XD-2383 - DND V2
  ADD_DRAG_ITEM_TO_BOARD: 'ADD_DRAG_ITEM_TO_BOARD',
  REMOVE_ITEM_FROM_BOARD: 'REMOVE_ITEM_FROM_BOARD',
  REORDER_MY_FILES: 'REORDER_MY_FILES',
  REORDER_MY_FILES_DOCUMENT_ITEM: 'REORDER_MY_FILES_DOCUMENT_ITEM',
  REMOVE_MULTIPLE_ITEM_FROM_MY_FILES: 'REMOVE_MULTIPLE_ITEM_FROM_MY_FILES',
  UPDATE_MY_FILES_MULTI_SELECT_FROM_DND: 'UPDATE_MY_FILES_MULTI_SELECT_FROM_DND',
  ADD_ITEMS_TO_MY_FILES_PAGE: 'ADD_ITEMS_TO_MY_FILES_PAGE',
  RESET_MY_FILES_CREATED_PAGE_ID: 'RESET_MY_FILES_CREATED_PAGE_ID',
  UPDATE_INDEX_SECTION: 'UPDATE_INDEX_SECTION',
  MY_FILES_DELETE_OBJECT: 'MY_FILES_DELETE_OBJECT',
  DELETE_CATEGORY: 'DELETE_CATEGORY',
  CREATE_CATEGORY_LOADER: 'CREATE_CATEGORY_LOADER',
  CREATE_FOLDER_LOADER: 'CREATE_FOLDER_LOADER',
  ADD_COPIED_ITEM_TO_MY_FILES_DOCUMENT: 'ADD_COPIED_ITEM_TO_MY_FILES_DOCUMENT',
  MY_FILES_SEARCH_PAGE_ID: 'MY_FILES_SEARCH_PAGE_ID',
  GET_MY_FILES_CATEGORY_ARCHIVED_LIST: 'GET_MY_FILES_CATEGORY_ARCHIVED_LIST',
  ARCHIVE_CATEGORY: 'ARCHIVE_CATEGORY',
  ARCHIVE_INDEX_SECTION_CATEGORY: 'ARCHIVE_INDEX_SECTION_CATEGORY',
  UN_ARCHIVE_INDEX_SECTION_CATEGORY: 'UN_ARCHIVE_INDEX_SECTION_CATEGORY',
  DELETE_ARCHIVED_CATEGORY: 'DELETE_ARCHIVED_CATEGORY',
  SET_ALL_GROUPS_LIST_LOADER: 'SET_ALL_GROUPS_LIST_LOADER',
  DELETE_MY_FILES_ITEM_VIA_PUSHER: 'DELETE_MY_FILES_ITEM_VIA_PUSHER',
  SET_HIDE_EMPTY_CONTAINER: 'SET_HIDE_EMPTY_CONTAINER',
  SET_HIDE_EMPTY_CONTAINER_MODAL_VISIBILIITY:
    'SET_HIDE_EMPTY_CONTAINER_MODAL_VISIBILIITY',
  UPDATE_MY_FILES_ITEM_SIZE: 'UPDATE_MY_FILES_ITEM_SIZE',
  UPDATE_MY_FILES_LAST_ITEM_PROPERTIES: 'UPDATE_MY_FILES_LAST_ITEM_PROPERTIES',
};

export default actions;
