import actions from './actions';
import { getTimezoneFormat } from '../../utility/helpers';
const initState = {
    isIndividualItem: false,
    isJotsActivity: false,
    isNoteEdit: false,
    isNoteCreateSave: false,
    isNoteCreateApiInitiated: false,
    deleteAsyncLoaderVisibility: false,
    itemCutted: [],
    isCaptureItemCut: false,
    droppedOnOverId: null,
    noteTitle: '',
    propertyUpdateLoader: false,
    folders: {
      folderVisibility: false,
      status: '',
      currentNodeId: undefined,
      archiveStatus: undefined,
      projectId: '',
    },
    share: {
      saveVisibility: false,
      saveHomeVisibility: false,
    },
    homeDetails: {
      folderVisibility: false,
      status: '',
      currentNodeId: undefined,
      archiveStatus: undefined,
    },
    sparks: {
      folderVisibility: false,
      status: '',
      currentNodeId: undefined,
      archiveStatus: undefined,
    },
    foldersUnArchive: {
      visibility: false,
      currentNodeId: undefined,
    },
    homeFoldersUnArchive: {
      visibility: false,
      currentNodeId: undefined,
    },
    sparksUnArchive: {
      unArchiveModalVisible: false,
      unArchiveCurrentNodeId: undefined,
    },
    forgotPassword: {
      forgotPasswordInfoModalVisible: false,
    },
    emptyLockDocumentHome: {
      visibility: false,
      currentNodeId: false,
    },
    emptyLockDocumentFolder: {
      visibility: false,
      currentNodeId: false,
    },
    changePasswordModal: {
      changePasswordModalVisible: false,
    },
    reactivateAccountModal: {
      reactivateAccountModalVisible: false,
    },
    rotatedAngle: '',
    activityUnArchive: {
      activityUnArchiveModalVisible: false,
      activityUnArchiveCurrentNodeId: undefined,
    },
    upgradeModal: {
      upgradeModalVisible: false,
      upgradeModalMessage: undefined,
    },
    sharedSpark: {
      visibility: false,
    },
    folderDropZoneVisibility: false,
    resendLinkModal: {
      visible: false,
    },
    invalidPassword: {
      visible: false,
    },
    badCredentials: {
      visible: false,
    },
    homeNewFolderVisibility: {
      visibility: false,
    },
    folderCreateModalVisibility: {
      visibility: false,
    },
    folderCreateProjectId: '',
    newFolderModalData: '',
    signOutAlert: {
      visible: false,
    },
    droppableOverlayVisible: false,
    websitePlanIdToSubscribe: null,
    websitePlanIdAlreadySubScribedInfoModal: {
      visibility: false,
    },
    accountNotVerifiedInfoModal: {
      visibility: false,
    },
    unArchiveFolderModal: {
      visibility: false,
      node_id: '',
      project_id: '',
      isLock: '',
      folderTitle: '',
      isFromLock: false,
      isHomeActivity: '',
      isFromViewSpark: false,
      previewNodeDetails: [],
    },
    deleteBoardGroupModalVisible: {
      visibility: false,
    },
    deleteBoardModalVisible: {
      visibility: false,
    },
    deleteFolderModalVisible: {
      visibility: false,
    },
    deleteDocumentModalVisible: {
      visibility: false,
    },
    selectedItemsDeleteModalVisible: {
      visibility: false,
    },
    redirectToHomeFolderModalVisible: {
      visibility: false,
    },
    sparkRestoreModal: {
      visibility: false,
      data: undefined,
    },
    downloadModal: {
      visibility: false,
      isFromCapture: false,
    },
    downloadProgress: {},
    completedPercentage: {},
    sparkHeaderImage: {},
    isSharedFromSpark: false,
    lastSelectedNodeId: null,
    isfromFolder: false,
    createdFolderNodeId: undefined,
    createdFolderTitle: null,
    overDragId: null,
    itemExpandCollapse:
      JSON.parse(localStorage.getItem('itemExpandCollapse')) || {},
    documentItemExpandCollapse:
      JSON.parse(localStorage.getItem('documentItemExpandCollapse')) || {},
    individualItemExpandCollapse:
      JSON.parse(localStorage.getItem('individualItemExpandCollapse')) || {},
    documentItemMixedStateView: {},
    documentExpandCollapse:
      JSON.parse(localStorage.getItem('documentExpandCollapse')) || {},
    documentItemView:
      JSON.parse(localStorage.getItem('documentItemView')) || {},
    savedNoteContent: undefined,
    duplicateData: {
      name: undefined,
      nodeId: null,
      visibility: false,
      parent: null,
      duplicateType: undefined,
      translation: undefined,
      isDocument: false,
    },
    duplicationProgress: {
      total_items_count: 0,
      completed_items: 0,
      total_storage: 0,
      completed_storage: 0,
      progress_status: undefined,
    },
    duplicateNotificationClosedId: [],
    avatarUrl: null,
    avatarData: {},
    avatarLoader: false,
    isAvatarUpdated: false,
    plansDrawerTitle: 'plan_drawer_title',
    plansDrawerVisibility: false,
    socialMediaModal: {
      visibility: false,
      shareData: null,
      isShareIndividualItem: false,
      loader: false,
    },
    removeDuplicateNotificationClosedId: false,
    urlFetchData: undefined,
    urlFetchLoader: false,
    jotsDocumentToBoardsDocumentCreationPayload: null,
    activeData: null,
    overData: null,
    multipleSelectDragItemDetails: null,
    documentDetails: [],
    fullScreenFolderDeleteModalData: {
      visibility: false,
      id: null,
      projectId: null,
      nodeId: null,
      itemId: null,
      deleteParent: null,
    },
    allArchivedList: {},
    urlPreventDefault: false,
    expandCollapseAll:
      JSON.parse(localStorage.getItem('expandCollapseAll')) || {},
    onBoardingSteps: 0,
    slidesRenderingFlow: 'sw',
    onBoardingTour: {
      visibility: false,
      drawerPlacement: 'right',
      mask: false,
      triggerPopOver: false,
      passValidation: true,
      currentStep: 0,
      target: null,
      module: 'pre_named_files',
      slidesRenderingFlow: '',
      slidesVisibility: false,
      completedStep: 2,
    },
    createPageLoader: false,
    fullScreenPageMultiSelect: [],
    captureNotification: null,
    myFilesNotification: null,
    jotsNotification: null,
    preventApiAction: false,
  },
  resetNewFolderFromSparkState = {
    createNewFolderFromSpark: false,
    isSharedFromSpark: false,
  };
const documentDetailsInitState = {
  documentDetails: [],
  documentProjectId: undefined,
  documentNodeId: undefined,
  documentParentMeta: undefined,
};
export default function commonReducer(state = initState, action) {
  switch (action.type) {
    case actions.SET_COMMON_ALERT_MODAL_VISIBLE: {
      const { module, payload, clearValue } = action;
      if (clearValue) {
        return {
          ...initState,
          sortByFilter: state.sortByFilter,
        };
      }
      return {
        ...state,
        [module]: payload,
      };
    }
    case actions.SET_NOTE_CREATE_SAVE_STATE: {
      const { payload } = action,
        { isNoteCreateSave, isNoteCreateApiInitiated } = payload;
      return {
        ...state,
        isNoteCreateSave: isNoteCreateSave,
        isNoteCreateApiInitiated: isNoteCreateApiInitiated,
      };
    }
    case actions.SET_IS_EDIT_NOTE_STATE: {
      const { payload } = action;
      return {
        ...state,
        isNoteEdit: payload,
      };
    }
    case actions.ROTATE_FULLSCREEN_IMAGE_ANGLE: {
      const { payload, reset } = action,
        { rotatedAngle } = state;
      if (reset) {
        return {
          ...state,
          rotatedAngle: 0,
        };
      }
      return {
        ...state,
        rotatedAngle:
          rotatedAngle || rotatedAngle === 0
            ? (rotatedAngle + payload) % 360
            : payload % 360,
      };
    }
    case actions.SET_FOLDER_DROP_ZONE_VISIBILITY: {
      const { payload } = action;
      return {
        ...state,
        folderDropZoneVisibility: payload,
      };
    }
    case actions.SHOW_CREATE_FOLDER_MODAL: {
      const { projectId, modalData, isFromFolderItem, selectedType } = action;
      return {
        ...state,
        folderCreateProjectId: projectId || '',
        newFolderModalData: modalData || '',
        isFromFolderItem: isFromFolderItem || false,
        selectedType,
      };
    }
    case actions.DROPPABLE_OVERLAY_VISIBLE: {
      const { payload } = action;
      return {
        ...state,
        droppableOverlayVisible: payload,
      };
    }
    case actions.UPDATE_WEBSITE_PLAN_ID_TO_SUBSCRIBE: {
      return {
        ...state,
        websitePlanIdToSubscribe: action?.payload || null,
        planNature: action?.planNature || null,
      };
    }
    case actions.DOWNLOAD_PROGRESS_STATUS: {
      return {
        ...state,
        downloadProgress: action.payload,
      };
    }
    case actions.COMPLETED_PERCENTAGE: {
      return {
        ...state,
        completedPercentage: action.payload,
      };
    }
    case actions.UPDATE_SPARK_HEADER_IMAGE: {
      return {
        ...state,
        sparkHeaderImageDetails: action?.payload,
      };
    }
    case actions.UPDATE_SPARK_PROFILE_IMAGE: {
      return {
        ...state,
        sparkProfileImageDetails: action?.payload,
      };
    }
    case actions.CREATE_NEW_FOLDER_FROM_SPARK: {
      return {
        ...state,
        createNewFolderFromSpark: action.payload,
        isSharedFromSpark: action.isSharedFromSpark,
      };
    }
    case actions.RESET_NEW_FOLDER_FROM_SPARK_STATE: {
      return {
        ...state,
        ...resetNewFolderFromSparkState,
      };
    }
    case actions.SET_LAST_SELECTED_NODE_ID: {
      return {
        ...state,
        lastSelectedNodeId: action.payload,
      };
    }
    case actions.SET_MOVE_MODAL_VISIBILITY: {
      const {
        moveModalVisibility,
        selectedItems,
        selectedNodeId,
        isfromFolder,
        selectedMoveFolderNodeId,
        selectedMoveFolderProjectId,
      } = action;
      return {
        ...state,
        moveModalVisibility,
        selectedItems,
        selectedNodeId,
        isfromFolder,
        selectedMoveFolderNodeId,
        selectedMoveFolderProjectId,
      };
    }
    case actions.SET_SELECTED_MENU_ID: {
      const { selectedMenuProjectId, selectedMenuNodeId } = action;
      return {
        ...state,
        selectedMenuProjectId,
        selectedMenuNodeId,
      };
    }
    case actions.CREATED_FOLDER_NODE_ID: {
      const { createdFolderNodeId, createdFolderTitle } = action;
      return {
        ...state,
        createdFolderNodeId,
        createdFolderTitle,
      };
    }
    case actions.PLANS_COMMON_DRAWER_VISIBILITY: {
      const { visibility, isFromSwapBoard } = action;
      return {
        ...state,
        plansDrawerVisibility: visibility,
        isFromSwapBoard,
      };
    }
    case actions.PLANS_COMMON_DRAWER_COMPONENTS_VISIBILITY: {
      const {
        plansModalVisibility,
        plansDrawerTitle,
        plansDrawerContent,
        isFromSwapBoard,
      } = action;
      return {
        ...state,
        plansModalVisibility,
        plansDrawerTitle,
        plansDrawerContent,
        isFromSwapBoard,
      };
    }
    case actions.PLANS_COMMON_POPUP_MODAL_VISIBILITY: {
      const { visibility, plansModalContent } = action;
      return {
        ...state,
        plansPopupModalVisibility: visibility,
        plansModalContent,
      };
    }
    case actions.UPDATE_PLANS_DRAWER_TITLE: {
      const { plansDrawerTitle } = action;
      return {
        ...state,
        plansDrawerTitle,
      };
    }
    case actions.MOVE_MODAL_STATE: {
      const { isFromMoveModal } = action;
      return {
        ...state,
        isFromMoveModal,
      };
    }
    case actions.CREATE_MODAL_BUTTON_DISABLE: {
      const { createModalButtonsDisable } = action;
      return {
        ...state,
        createModalButtonsDisable,
      };
    }
    case actions.SET_ACTIVE_DRAG_ID: {
      return {
        ...state,
        activeDragDetails: action.payload,
        isIndexSection: action.isIndexSection,
      };
    }
    case actions.SET_OVER_DRAG_ID: {
      return {
        ...state,
        overDragId: action.payload,
        hasChild: action.hasChild,
        isIndexSection: action.isIndexSection,
        overDropDetails: action.overDropDetails,
      };
    }
    case actions.RESET_OVER_ID: {
      return {
        ...state,
        overDragId: initState.overDragId,
        isIndexSection: false,
      };
    }
    case actions.DROP_ZONE_DETAILS: {
      return {
        ...state,
        droppedData: action.payload,
        isNode: action.isNode,
        activeData: action.activeData,
        overData: action.overData,
        multipleSelectDragItemDetails: action.multipleSelectDragItemDetails,
        jotsDocumentToBoardsDocumentCreationPayload:
          action.jotsDocumentToBoardsDocumentCreationPayload,
        createFolderFromDropZoneDragItemDetails:
          action.createFolderFromDropZoneDragItemDetails,
      };
    }
    case actions.CREATE_MODULE_TYPE: {
      return {
        ...state,
        createModuleType: action.payload,
      };
    }
    case actions.CONTEXT_MENU_DETAILS: {
      const { isJotsActivity, isIndividualItem } = action;
      return {
        ...state,
        isJotsActivity: isJotsActivity,
        isIndividualItem: isIndividualItem,
      };
    }
    case actions.GET_ALL_ARCHIVED_LIST: {
      return {
        ...state,
        archivedListLoader: true,
      };
    }
    case actions.GET_ALL_ARCHIVED_LIST_SUCCESS: {
      return {
        ...state,
        allArchivedList: action.payload,
        archivedListLoader: false,
      };
    }
    case actions.ITEMS_EXPAND_COLLAPSE: {
      const { payload, nodeId, isCollapse } = action;
      let documentItemView;
      if (state.documentItemView?.[nodeId]?.isMixed) {
        documentItemView = {
          ...state.documentItemView,
          [nodeId]: {
            ...state.documentItemView[nodeId],
            isMixed: false,
            isIndividual: true,
            mixedState: {
              ...state.documentItemView?.[nodeId]?.mixedState,
              [payload]: isCollapse,
            },
            individual: {
              ...state.documentItemView?.[nodeId]?.individual,
              ...state.documentItemView?.[nodeId]?.mixedState,
              [payload]: isCollapse,
            },
          },
        };
      } else {
        documentItemView = {
          ...state.documentItemView,
          [nodeId]: {
            ...state.documentItemView[nodeId],
            isMixed: false,
            isIndividual: true,
            mixedState: {
              ...state.documentItemView?.[nodeId]?.mixedState,
              [payload]: isCollapse,
            },
            individual: {
              ...state.documentItemView?.[nodeId]?.individual,
              [payload]: isCollapse,
            },
          },
        };
      }
      localStorage.setItem(
        'documentItemView',
        JSON.stringify(documentItemView),
      );
      return {
        ...state,
        documentItemView,
      };
    }
    case actions.DOCUMENT_ITEMS_EXPAND_COLLAPSE: {
      const { payload } = action;
      const documentItemView = {
        ...state.documentItemView,
        [payload]: {
          ...state.documentItemView[payload],
          isMixed: false,
          isIndividual: false,
          isCollapse: !state.documentItemView[payload]?.isCollapse,
          individual: {},
        },
      };
      localStorage.setItem(
        'documentItemView',
        JSON.stringify(documentItemView),
      );
      return {
        ...state,
        documentItemView,
      };
    }
    case actions.DOCUMENT_ITEMS_MIXED_STATE_VIEW: {
      const { payload } = action;
      const documentItemView = {
        ...state.documentItemView,
        [payload]: {
          ...state.documentItemView[payload],
          isMixed: true,
          isIndividual: false,
        },
      };
      localStorage.setItem(
        'documentItemView',
        JSON.stringify(documentItemView),
      );
      return {
        ...state,
        documentItemView,
      };
    }

    case actions.DOCUMENT_EXPAND_COLLAPSE_VIEW: {
      const { payload } = action,
        documentExpandCollapse = { ...state.documentExpandCollapse };
      if (documentExpandCollapse.hasOwnProperty(payload)) {
        delete documentExpandCollapse[payload];
      } else {
        documentExpandCollapse[payload] = null;
      }
      localStorage.setItem(
        'documentExpandCollapse',
        JSON.stringify(documentExpandCollapse),
      );
      return {
        ...state,
        documentExpandCollapse,
      };
    }
    case actions.INDIVIDUAL_ITEMS_EXPAND_COLLAPSE: {
      const { payload } = action,
        individualItemExpandCollapse = {
          ...state.individualItemExpandCollapse,
        };
      if (individualItemExpandCollapse.hasOwnProperty(payload)) {
        delete individualItemExpandCollapse[payload];
      } else {
        individualItemExpandCollapse[payload] = null;
      }
      localStorage.setItem(
        'individualItemExpandCollapse',
        JSON.stringify(individualItemExpandCollapse),
      );
      return {
        ...state,
        individualItemExpandCollapse,
      };
    }
    case actions.SET_FILTER: {
      const { sortBy, filterId } = action;
      return {
        ...state,
        sortByFilter: {
          ...state.sortByFilter,
          [filterId]: sortBy,
        },
      };
    }
    case actions.HIGHLIGHT_ITEM: {
      return {
        ...state,
        highlightItem: action.payload,
      };
    }
    case actions.TRIGGER_TARGET_ITEM_HIGHLIGHT: {
      return {
        ...state,
        targetItemHighlightStatus: action.payload,
      };
    }
    case actions.DUPLICATE_MODAL: {
      const { payload } = action;
      return {
        ...state,
        duplicateData: {
          ...state.duplicateData,
          ...payload,
        },
      };
    }
    case actions.RESET_DUPLICATE_MODAL_DATA: {
      return {
        ...state,
        duplicateData: initState.duplicateData,
      };
    }
    case actions.RESET_DUPLICATION_PROGRESS: {
      return {
        ...state,
        duplicationProgress: initState.duplicationProgress,
      };
    }
    case actions.CLOSE_DUPLICATE_NOTIFICATION: {
      return {
        ...state,
        duplicateNotificationClosedId: action.removeDuplicateNotificationClosedId
          ? action.payload
          : [...state.duplicateNotificationClosedId, action.payload],
      };
    }
    case actions.CLOSE_DUPLICATE_MODAL: {
      return {
        ...state,
        duplicateData: {
          ...state.duplicateData,
          visibility: false,
        },
      };
    }
    case actions.DUPLICATION_PROGRESS: {
      return {
        ...state,
        duplicationProgress:
          action.payload.progress_status !== 'pending'
            ? {
              ...state.duplicationProgress,
              ...action.payload,
            }
            : {
              ...initState.duplicationProgress,
              ...action.payload,
              },
      };
    }
    case actions.SAVE_NOTE_CONTENT: {
      return {
        ...state,
        savedNoteContent: action.payload,
      };
    }
    case actions.AVATAR_URL: {
      const { avatarUrl, avatarData, isAvatarUpdated } = action;
      return {
        ...state,
        avatarUrl,
        avatarData,
        isAvatarUpdated,
      };
    }
    case actions.RESET_AVATAR_URL: {
      return {
        ...state,
        avatarUrl: initState.avatarUrl,
        avatarData: initState.avatarData,
        isAvatarUpdated: initState.isAvatarUpdated,
      };
    }
    case actions.AVATAR_LOADER: {
      return {
        ...state,
        avatarLoader: action.payload,
      };
    }
    case actions.SET_SWAP_BOARD_FLAG: {
      return {
        ...state,
        swapBoardFlag: action.payload,
      };
    }
    case actions.SOCIAL_MEDIA_MODAL: {
      return {
        ...state,
        socialMediaModal: {
          ...state.socialMediaModal,
          ...action.socialMediaModal,
        },
      };
    }
    case actions.RESET_SOCIAL_MEDIA_MODAL: {
      return {
        ...state,
        socialMediaModal: initState.socialMediaModal,
      };
    }
    case actions.SET_REDIRECTED_ITEM_DETAILS: {
      const { payload } = action;
      return {
        ...state,
        redirectedItemDetails: payload?.redirectedItemDetails,
      };
    }
    case actions.FETCH_SHARE_URL: {
      return {
        ...state,
        urlFetchLoader: true,
      };
    }
    case actions.FETCH_SHARE_URL_SUCCESS: {
      return {
        ...state,
        urlFetchData: action.payload,
        urlFetchLoader: false,
      };
    }
    case actions.FETCH_SHARE_URL_FAILURE: {
      return {
        ...state,
        urlFetchLoader: false,
      };
    }
    case actions.GET_DOCUMENT_DETAILS: {
      return {
        ...state,
        fullScreenDocumentLoader: true,
      };
    }
    case actions.GET_DOCUMENT_DETAILS_SUCCESS: {
      return {
        ...state,
        documentDetails: action.payload,
        documentProjectId: action.projectId,
        documentNodeId: action.nodeId,
        documentParentMeta: action.parentMeta,
        fullScreenDocumentLoader: false,
      };
    }
    case actions.DUPLICATE_LIST_VIEW_FULLSCREEN_DOCUMENT_ITEM: {
      const { itemId, name, tempId } = action.payload,
        duplicatedItem = state.documentDetails.find(
          (item) => item.id === itemId,
        ),
        updateDuplicateItem = {
          ...duplicatedItem,
          name,
          created_at: getTimezoneFormat(),
          updated_at: getTimezoneFormat(),
          id: tempId,
          no_of_time_shared: null,
          properties: {
            ...duplicatedItem,
            x_coordinate: (duplicatedItem?.x_coordinate || 0) + 30,
            y_coordinate: (duplicatedItem?.y_coordinate || 0) + 30,
          },
          url: null,
        };
      return {
        ...state,
        documentDetails: [updateDuplicateItem, ...state.documentDetails],
      };
    }
    case actions.DUPLICATE_LIST_VIEW_FULLSCREEN_DOCUMENT_ITEM_SUCCESS: {
      const { payload, tempId } = action,
        updatedItems = state.documentDetails.map((item) => {
          if (item.id === tempId) return payload;
          return item;
        });
      return {
        ...state,
        documentDetails: updatedItems,
      };
    }
    case actions.DELETE_LIST_VIEW_FULLSCREEN_DOCUMENT_ITEM: {
      const { itemIds } = action,
        updatedDocument = state.documentDetails.map((document) => {
          if (itemIds.includes(document.id)) {
            return {
              ...document,
              is_delete: true,
            };
          }
          return document;
        });
      return {
        ...state,
        documentDetails: updatedDocument,
        itemDeleteModalVisibility: false,
      };
    }
    case actions.ADD_LIST_VIEW_FULLSCREEN_DOCUMENT_ITEM: {
      return {
        ...state,
        documentDetails: [...state.documentDetails, action.payload],
      };
    }
    case actions.ADD_LIST_VIEW_FULLSCREEN_DOCUMENT_ITEM_SUCCESS: {
      return {
        ...state,
        documentDetails: [...state.documentDetails, action.payload],
      };
    }
    case actions.EDIT_LIST_VIEW_FULLSCREEN_DOCUMENT_ITEMS: {
      const { payload } = action,
        updateDocumentItems = state.documentDetails.map((item) => {
          if (item.id === payload.id) {
            return payload;
          }
          return item;
        });
      return {
        ...state,
        documentDetails: updateDocumentItems,
      };
    }
    case actions.REORDER_FULLSCREEN_DOCUMENT_ITEM: {
      return {
        ...state,
        documentDetails: action.changedOrderList,
      };
    }
    case actions.UPDATE_LIST_VIEW_FULLSCREEN_DOCUMENT_DETAILS: {
      return {
        ...state,
        documentParentMeta: {
          ...state.documentParentMeta,
          name: action.payload.name,
          description: action.payload?.description || null,
        },
      };
    }
    case actions.RESET_DOCUMENT_DETAILS: {
      return {
        ...state,
        ...documentDetailsInitState,
      };
    }
    case actions.UPDATE_LIST_VIEW_FULLSCREEN_DOCUMENT_CONTACT_ITEM_SUCCESS: {
      const { payload } = action;
      return {
        ...state,
        documentDetails: [...state.documentDetails, ...payload],
      };
    }
    case actions.FULLSCREEN_DOCUMENT_DELETE_MODAL: {
      return {
        ...state,
        fullScreenFolderDeleteModalData: action.fullScreenFolderDeleteModalData,
      };
    }
    case actions.RESET_FULLSCREEN_DOCUMENT_DELETE_MODAL: {
      return {
        ...state,
        fullScreenFolderDeleteModalData:
          initState.fullScreenFolderDeleteModalData,
      };
    }
    case actions.UPDATE_ALL_ARCHIVED_LIST: {
      const updatedList = {
        ...state.allArchivedList,
        [action.itemType]: state.allArchivedList?.[action.itemType]?.filter(
          (board) => board[action.key] !== action.payload,
        ),
      };
      return {
        ...state,
        allArchivedList: updatedList,
      };
    }
    case actions.DELETE_ALL_ARCHIVE_ITEM: {
      const { itemType, deleteId, key } = action;
      const updatedList = {
        ...state.allArchivedList,
        [itemType]: state.allArchivedList?.[itemType]?.map((board) => {
          if (board[key] === deleteId) {
            return {
              ...board,
              is_delete: true,
            };
          }
          return board;
        }),
      };
      return {
        ...state,
        allArchivedList: updatedList,
      };
    }
    case actions.UPDATE_ALL_ARCHIVED_LIST_ITEM: {
      const updatedList = {
        ...state.allArchivedList,
        [action.itemType]: [
          { ...action.data, archived: true },
          ...state.allArchivedList[action.itemType],
        ],
      };
      return {
        ...state,
        allArchivedList: updatedList,
      };
    }
    case actions.RESET_ALL_ARCHIVED_LIST: {
      return {
        ...state,
        allArchivedList: initState.allArchivedList,
      };
    }
    case actions.URL_PREVENT_DEFAULT: {
      return {
        ...state,
        urlPreventDefault: !state.urlPreventDefault,
      };
    }
    case actions.EXPAND_COLLAPSE_ALL: {
      const { nodeId, ids } = action;
      const updatedList = {
        ...state.expandCollapseAll,
        [nodeId]: {
          ...state.expandCollapseAll?.[nodeId],
          ind: !state.expandCollapseAll?.[nodeId]?.ind?.length
            ? state.expandCollapseAll?.[nodeId]?.all || []
            : state.expandCollapseAll?.[nodeId]?.ind || [],
          all: ids,
        },
      };
      localStorage.setItem('expandCollapseAll', JSON.stringify(updatedList));
      return {
        ...state,
        expandCollapseAll: updatedList,
      };
    }
    case actions.EXPAND_COLLAPSE_IND: {
      const { nodeId, itemId } = action;
      const updatedList = {
        ...state.expandCollapseAll,
        [nodeId]: {
          ...state.expandCollapseAll?.[nodeId],
          all: state.expandCollapseAll?.[nodeId]?.all?.includes(itemId)
            ? state.expandCollapseAll?.[nodeId]?.all?.filter(
                (id) => id !== itemId,
              )
            : [...(state.expandCollapseAll?.[nodeId]?.all || []), itemId],
          ind: [],
        },
      };
      localStorage.setItem('expandCollapseAll', JSON.stringify(updatedList));
      return {
        ...state,
        expandCollapseAll: updatedList,
      };
    }
    case actions.EXPAND_COLLAPSE_MIXED: {
      const { nodeId } = action;
      const updatedList = {
        ...state.expandCollapseAll,
        [nodeId]: {
          ...state.expandCollapseAll?.[nodeId],
          all: state.expandCollapseAll?.[nodeId]?.ind || [],
          ind: [],
        },
      };
      localStorage.setItem('expandCollapseAll', JSON.stringify(updatedList));
      return {
        ...state,
        expandCollapseAll: updatedList,
      };
    }
    case actions.UPDATE_ON_BOARDING_STEPS: {
      return {
        ...state,
        onBoardingSteps:
          action.isBack && state.onBoardingSteps > 0
            ? state.onBoardingSteps - 1
            : action.payload || state.onBoardingSteps,
        slidesRenderingFlow:
          action.slidesRenderingFlow || state.slidesRenderingFlow,
      };
    }
    case actions.ONBOARDING_TOUR: {
      const { onBoardingTour } = action;
      return {
        ...state,
        onBoardingTour: {
          ...state.onBoardingTour,
          ...onBoardingTour,
        },
      };
    }
    case actions.RESET_ONBOARDING_TOUR: {
      return {
        ...state,
        // onBoardingTour: initState.onBoardingTour,
      };
    }
    case actions.PRE_CONFIG_ALERT_VISIBILITY: {
      return {
        ...state,
        preConfigAlertVisibility: action.payload,
        preConfigUnCheckCallback: action.callback,
      };
    }
    case actions.CREATE_NEW_FOLDER: {
      return {
        ...state,
        createPageLoader: true,
      };
    }
    case actions.CREATE_NEW_FOLDER_SUCCESS: {
      return {
        ...state,
        createPageLoader: false,
      };
    }
    case actions.CREATE_NEW_FOLDER_FAILURE: {
      return {
        ...state,
        createPageLoader: false,
      };
    }
    case actions.FULL_SCREEN_PAGE_MULTI_SELECT: {
      if (action.selectAll) {
        return {
          ...state,
          fullScreenPageMultiSelect: action.payload,
        };
      } else if (action.unSelectAll) {
        return {
          ...state,
          fullScreenPageMultiSelect: initState.fullScreenPageMultiSelect,
        };
      } else if (action.checked) {
        return {
          ...state,
          fullScreenPageMultiSelect: [
            ...state.fullScreenPageMultiSelect,
            action.id,
          ],
        };
      } else {
        return {
          ...state,
          fullScreenPageMultiSelect: state.fullScreenPageMultiSelect.filter(
            (id) => id !== action.id,
          ),
        };
      }
    }
    case actions.SET_CAPTURES_NOTIFICATION_OBJECT: {
      return {
        ...state,
        captureNotification: action.payload,
      };
    }
    case actions.SET_MY_FILES_NOTIFICATION_OBJECT: {
      return {
        ...state,
        myFilesNotification: action.payload,
      };
    }
    case actions.SET_JOTS_NOTIFICATION_OBJECT: {
      return {
        ...state,
        jotsNotification: action.payload,
      };
    }
      case actions.DRAG_START: {
          return {
              ...state,
              dragItemType: action.dragItemType,
          };
      }
    case actions.SET_DRAG_DETAILS: {
      const { active, over } = action.payload;
      return {
        ...state,
        activeDragData: active,
        overDragData: over,
      };
    }
    case actions.SET_DROP_ACTION: {
      const { dropAction } = action.payload;
      return {
        ...state,
        dropAction: dropAction,
      };
    }
    case actions.DELETE_ASYNC_LOADER_VISIBILITY: {
      const { visibility } = action.payload;
      return {
        ...state,
        deleteAsyncLoaderVisibility: visibility,
      };
    }
    case actions.CUT_ITEM: {
      const { itemCutted, isCaptureItemCut } = action.payload;
      return {
        ...state,
        itemCutted: itemCutted,
        isCaptureItemCut: isCaptureItemCut,
      };
    }
    case actions.CLEAR_CUT_ITEM: {
      return {
        ...state,
        itemCutted: [],
        isCaptureItemCut: false,
      };
    }
    case actions.PREVENT_API_ACTION_FROM_SEARCH: {
      return {
        ...state,
        preventApiAction: action.payload,
      };
    }
    case actions.SET_DROPPED_DATA: {
      const { droppedOnOverId } = action.payload;
      return {
        ...state,
        droppedOnOverId: droppedOnOverId,
      };
    }
    case actions.SAVE_NOTE_TITLE: {
      return {
        ...state,
        noteTitle: action.payload,
      };
    }
    case actions.SET_PROPERTIES_LOADER: {
      return {
        ...state,
        propertyUpdateLoader: action.payload,
      };
    }
    default:
      return state;
  }
}
