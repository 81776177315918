import React from 'react';
import Droppable from 'components/dnd/droppableContainer';
import { Translate} from 'utility/helpers';
import { ReactComponent as FolderIcon } from 'images/svgs/folder.svg';
import { ReactComponent as DocumentIcon } from 'images/svgs/icon-document.svg';
import { ReactComponent as PublishedDocument } from '../../images/svgs/latest-V2/iconPublishedDocument.svg';
import { store } from 'redux/store';
import actions from 'redux/boardGroups/actions';
import commonActions from 'redux/common/actions';
import searchActions from 'redux/search/actions';
import { CONTAINER_TYPES } from '../dnd/constant';
import { resetMultipleMyFilesSelect } from '../dnd/actionHelper';

function getItem({
  label,
  key,
  icon,
  children,
  type,
  className,
  items,
  context = null,
  name,
  note,
  version,
  isDocument,
  nodeId,
  projectId,
  avatar,
  data,
  is_empty,
}) {
  return {
    key,
    icon,
    children,
    label,
    type,
    className,
    items,
    context,
    name,
    note,
    version,
    isDocument,
    nodeId,
    projectId,
    avatar,
    data,
    is_empty,
  };
}

function getChildrenNodes({ nodes = [], configModalVisibility }) {
  if (nodes.length === 0) {
    return [];
  }
  return nodes
    .filter(
      (data) =>
        data.object_type !== 'page' &&
        !data?.archived &&
        nodes &&
        checkPreloadState(configModalVisibility, data),
    )
    .map((node) => {
      const { id, name, folders } = node,
        hasChild = nodes?.length > 0,
        itemId = `${id}_menu`;

      return getItem({
        label: (
          <Droppable
            id={itemId}
            data={{
              type: CONTAINER_TYPES.INDEX_SECTION_FOLDER,
              container: CONTAINER_TYPES.INDEX_SECTION,
              destinationName: name || 'untitled',
              indexSection: true,
              nodeId: id,
              hasChild,
            }}
            isFromIndexSection={true}
          >
            <div
              onClick={(e) => {
                if (configModalVisibility && node?.user_updated) {
                  preConfigAlertModalVisibility(true);
                } else if (!configModalVisibility) {
                  if (store.getState().Search.searchResults?.length >= 0) {
                    store.dispatch({
                      type: searchActions.CLEAR_ALL_FILTERS,
                    });
                  }
                  e.stopPropagation();
                  expandHierarchyLevel({ selectedKey: id, name });
                }
              }}
              className={
                configModalVisibility && node?.user_updated
                  ? 'pre-invoke-label-disabled'
                  : ''
              }
            >
              <div className={'custom-menu-item'}>
                {getIconForNode(node?.avatar)}
                <p>{name || <Translate>untitled</Translate>}</p>
              </div>
            </div>
          </Droppable>
        ),
        name: node?.name,
        description: node?.description,
        key: id,
        // icon: getIconForNode(node?.avatar),
        className: `folders-section ${folders?.length ? '' : 'has-no-child'}`,
        children: getChildrenNodes({nodes: folders, configModalVisibility}),
        is_empty: node.is_empty,
      });
    });
}

export function menuItems({ groups, configModalVisibility, hideEmptyContainer }) {
  let result;
  result =
    groups &&
    groups
      ?.filter((data) => checkPreloadState(configModalVisibility, data) && data.visibility !== false)
      .map((data) => {
        return getItem({
          label: (
            <Droppable
              id={`${data?.id}_menu`}
              data={{
                type: CONTAINER_TYPES.INDEX_SECTION_CATEGORY,
                container: CONTAINER_TYPES.INDEX_SECTION,
                name: data?.name || 'untitled',
                indexSection: true,
                hasChild: true,
                isCategory: data?.object_type === 'category',
              }}
              isFromIndexSection={true}
            >
              <div
                onClick={(e) => {
                  if (configModalVisibility && data?.user_updated) {
                    preConfigAlertModalVisibility(true);
                  } else if (!configModalVisibility) {
                    e.stopPropagation();
                    if (store.getState().Search.searchResults?.length >= 0) {
                      store.dispatch({
                        type: searchActions.CLEAR_ALL_FILTERS,
                      });
                    }
                    expandHierarchyLevel({
                      selectedKey: data?.id,
                      name: data?.name,
                    });
                  }
                }}
                className={
                  configModalVisibility && data?.user_updated
                    ? 'pre-invoke-label-disabled'
                    : ''
                }
              >
                <p>{data?.name}</p>
              </div>
            </Droppable>
          ),
          name: data?.name,
          description: data?.description,
          key: data?.id,
          className: `group-boards ${data?.folders?.length ? '' : 'has-no-child'}`,
          children: getChildrenNodes({ nodes: data.folders }),
          is_empty: data.is_empty,
        });
      });
  return result;
}
export function getDetails(selectedId) {
    let result = null;  // Start with null to find the specific detail
    const { allGroupList } = store.getState().BoardGroups;

    if (allGroupList && Array.isArray(allGroupList)) {
        // Iterate over each group
        for (const group of allGroupList) {
            if (group && group.id) {
                // Check if the current group is the selected one
                if (group.id === selectedId) {
                    return {
                        id: group.id,
                        name: group.name,
                        type: 'group',
                        groupId: group.id,
                    };
                }

                // Check each project folder within the group
                if (group.folders && Array.isArray(group.folders)) {
                    for (const project of group.folders) {
                        if (project && project.id) {
                            // Check if the current project is the selected one
                            if (project.id === selectedId) {
                                return {
                                    id: project.id,
                                    name: project.name,
                                    groupId: group.id,
                                    type: 'folder',
                                    parentNodeId: group.id,
                                    immediateParentNodeName: group.name,
                                    parentType: project.parent_id === group.id ? 'group' : 'folder',
                                };
                            }

                            // Traverse nested folders recursively
                            if (project.folders && Array.isArray(project.folders)) {
                                const traverseNodes = (
                                    nodes,
                                    parentNodeId,
                                    immediateParentNodeName = <Translate>folder_name</Translate>,
                                    parentType
                                ) => {
                                    for (const node of nodes) {
                                        if (node && node.id) {
                                            // Check if the current node is the selected one
                                            if (node.id === selectedId) {
                                                return {
                                                    id: node.id,
                                                    name: node.name || <Translate>folder_name</Translate>,
                                                    groupId: group.id,
                                                    nodeId: node.id,
                                                    type: 'folder',
                                                    parentTitle: project.name,
                                                    parentNodeId: parentNodeId,
                                                    immediateParentNodeName: immediateParentNodeName,
                                                    parentType: parentType,
                                                    isDocument: node.object && node.object.type === 'page',
                                                    is_empty: node.is_empty,
                                                };
                                            }

                                            // Recursively call traverseNodes if there are more nested folders
                                            if (node.folders && Array.isArray(node.folders)) {
                                                const found = traverseNodes(
                                                    node.folders,
                                                    node.id,
                                                    node.name || <Translate>folder_name</Translate>,
                                                    'folder'
                                                );
                                                if (found) return found; // Return immediately if found
                                            }
                                        }
                                    }
                                    return null; // Return null if not found in this branch
                                };

                                const found = traverseNodes(
                                    project.folders,
                                    project.id,
                                    project.name,
                                    'folder'
                                );
                                if (found) return found; // Return immediately if found
                            }
                        }
                    }
                }
            }
        }
    }

    return result; // Return null if nothing found
}

export function getParentId(allGroupList) {
  let result = [];
  const defaultName = <Translate>untitled</Translate>;

  if (allGroupList && Array.isArray(allGroupList)) {
    allGroupList.forEach((group) => {
      if (group && group.id) {
        result.push({
          id: group.id,
          parentId: null,
          name: group.name || defaultName,
        });

        if (group.folders && Array.isArray(group.folders)) {
          group.folders.forEach((project) => {
            if (project && project.id) {
              result.push({
                id: project.id,
                parentId: project?.parent_id,
                name: project.name ||defaultName,
              });

              if (project.folders && Array.isArray(project.folders)) {
                const traverseNodes = (nodes, isRecursive) => {
                  nodes.forEach((node) => {
                    if (node && node.id) {
                      result.push({
                        id: node.id,
                        parentId: isRecursive || node?.parent_id,
                        name: node.name || defaultName,
                      });

                      if (node.folders && Array.isArray(node.folders)) {
                        traverseNodes(node.folders, node.id);
                      }
                    }
                  });
                };

                traverseNodes(project.folders);
              }
            }
          });
        }
      }
    });
  }
  return result;
}

export function expandHierarchyLevel(data) {
  const {
    selectedKey = null,
    keys = null,
    onSelectKey = true,
      name,
      isFromSearch,
      callback = () => {},
  } = data;
  const hasOnBoardingCompleted = store.getState().Auth.hasOnBoardingCompleted;
  const { allGroupList, openKeys } = store.getState().BoardGroups;
  if (onSelectKey) {
    resetMultipleMyFilesSelect();
  }
  if ((hasOnBoardingCompleted && allGroupList?.length) || isFromSearch) {
    let actionType = 'GET_FOLDER_DETAILS',
      activeType = 'folders';
    if (!onSelectKey && !isFromSearch) {
      store.dispatch({
        type: actions.OPEN_KEYS,
        payload: !keys ? [...openKeys, selectedKey] : keys,
      });
    } else {
        store.dispatch({
            type: actions.CLOUD_AREA_COMMON_LOADER,
            payload: true,
        });
        store.dispatch({
            type: actions.SET_HIERARCHY_DETAILS,
            currentId: selectedKey,
            title: name,
            activeType,
            currentType: 'folder',
        });
        store.dispatch({
            type: actions[actionType],
            selectedKey,
            callback,
        });
    }
  }
}

export function insideGroup() {
  const { currentType } = store.getState().BoardGroups;
  return currentType === 'group';
}
export function insideBoard() {
  const { currentType } = store.getState().BoardGroups;
  return currentType === 'board';
}
export function insideFolder() {
  const { currentType } = store.getState().BoardGroups;
  return currentType === 'folder';
}

export function isCloudAreaListView() {
    // TODO: card-2148 Change the condition after development
  const { cloudAreaDocumentView } = store.getState().BoardGroups;
  return cloudAreaDocumentView.view !== 'list';
}
export function lastApiAction(updateIndexSection = false) {
  const { lastApiAction } = store.getState().BoardGroups;
  return { type: actions[lastApiAction], updateIndexSection };
}

export function updateListViewData() {
  // TODO: Card-2148 Check and remove
  // const { addBarDetails } = store.getState().addBar;
  // isCloudAreaListView() &&
  //   !(
  //     addBarDetails.parent === 'jots' ||
  //     addBarDetails.parent === 'jots_document'
  //   ) &&
  //   store.dispatch(lastApiAction());
}

function getIconForNode(avatarUrl = null, isDocument = false, isShared = false) {
    if (avatarUrl) {
        return <div className={'index-avatar'}>
            <img src={avatarUrl} alt={'avatar'} />
        </div>
    } else if (isDocument) {
        if(isShared){
            return <PublishedDocument width={18} height={18}/>
        }else{
            return <DocumentIcon />
        }
    }
    return <FolderIcon />
}

export function getBreadCrumbItems() {
    const { allGroupList, currentId } = store.getState().BoardGroups;
    let result = [];
    const groupId = getDetails(currentId)?.groupId;
    const sortedIndexList = [allGroupList?.find(data => data?.parent_id === groupId || data?.id === groupId)];
    let findData = getParentId(sortedIndexList)?.find((id) => id?.id === currentId);

    while (findData !== undefined && findData?.parentId !== null) {
        result.push({key: findData?.id, title: findData?.name});
        // eslint-disable-next-line
        findData = getParentId(sortedIndexList)?.find((id) => id?.id === findData?.parentId);
    }

    result.push({key: findData?.id, title: findData?.name});

    return result.reverse();
}

function checkPreloadState(configModalVisibility, data) {
    if (!configModalVisibility) return true;
    return data?.is_selected_preload || (data?.user_updated && data.is_selected_preload === undefined);
}

export function preConfigAlertModalVisibility(visibility = false) {
    store.dispatch({
        type: commonActions.PRE_CONFIG_ALERT_VISIBILITY,
        payload: visibility,
    })
}

export function preConfigUnCheckAlertModalVisibility({visibility = false, callback }) {
    store.dispatch({
        type: commonActions.PRE_CONFIG_ALERT_VISIBILITY,
        payload: visibility,
        callback,
    })
}
export const removeTreeDataItem = (list, key) =>
  list
    .map((node) => {
      if (node.key === key) {
        return null; // Remove this node
      }
      if (node.children) {
        return {
          ...node,
          children: removeTreeDataItem(node.children, key).filter(Boolean), // Recursively remove from children
        };
      }
      return node;
    })
    .filter(Boolean);

export const updateIndexSection = ({ deletedId = null }) => {
  const renderHierarchyTree = store.getState().BoardGroups.renderHierarchyTree;
  const updatedRenderHierarchyTree = removeTreeDataItem(renderHierarchyTree, deletedId);
  store.dispatch({
    type: actions.UPDATE_INDEX_SECTION,
    payload: {
      updatedRenderHierarchyTree,
    }
  });
}

export const hideEmptyContainers = (list, visibility) => {
    function removeClass(str, className) {
        return str.replace(new RegExp(`\\b${className}\\b`, 'g'), '').trim();
    }
    return list
        .map((node) => {
            if (!node.is_empty && node.children) {
                return {
                    ...node,
                    children: hideEmptyContainers(node.children, visibility)
                };
            }
            return {
                ...node,
                className: node.is_empty && visibility
                    ? `${node.className} d-none`
                    : removeClass(node.className, 'd-none')
            };
        })
        .filter(Boolean);
}

export const handleHideEmptyIndexSection = (hideEmptyStatus) => {
    const renderHierarchyTree = store.getState().BoardGroups.renderHierarchyTree;
    const updatedRenderHierarchyTree = hideEmptyContainers(renderHierarchyTree, hideEmptyStatus);
    store.dispatch({
        type: actions.UPDATE_INDEX_SECTION,
        payload: {
            updatedRenderHierarchyTree,
        }
    });
}