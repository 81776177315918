export const TOUR_DATA_ATTRIBUTE = 'data-tour';

export const tourCompletedStep = 4;

export const tourAttributes = {
  TOUR_STEP_0: 'tour_step_0',
  TOUR_STEP_1: 'tour_step_1',
  TOUR_STEP_2: 'tour_step_2',
  TOUR_STEP_3: 'tour_step_3',
  TOUR_STEP_4: 'tour_step_4',
  TOUR_STEP_5: 'tour_step_5',
  TOUR_STEP_6: 'tour_step_6',
};
export const tourStep0 = {
  title: 'tour_instant_folder_tooltip_title',
  placement: 'right',
  className: 'step-0',
  target: tourAttributes.TOUR_STEP_0,
};
export const tourStep1 = {
  title: '1 of 5',
  description: 'tour_step_1_note',
  placement: 'left',
  className: 'step-1',
  mask: true,
  target: tourAttributes.TOUR_STEP_1,
};
export const tourStep2 = {
  title: '2 of 5',
  description: 'tour_step_2_note',
  placement: 'left',
  className: 'step-2',
  mask: true,
  target: tourAttributes.TOUR_STEP_2,
};
export const tourStep3 = {
  title: '3 of 5',
  description: 'tour_step_3_note',
  placement: 'right',
  className: 'step-3',
  mask: true,
  target: tourAttributes.TOUR_STEP_3,
};
export const tourStep4 = {
  title: '4 of 5',
  description: 'tour_step_4_note',
  placement: 'top',
  className: 'step-4',
  mask: true,
  target: tourAttributes.TOUR_STEP_4,
};
export const tourStep5 = {
  title: '5 of 5',
  description: 'tour_step_5_note',
  placement: 'bottomRight',
  className: 'step-5',
  mask: true,
  target: tourAttributes.TOUR_STEP_5,
  nextButtonLabel: 'end_tour',
};

export const tourStep6 = {
  description: 'tour_step_6_note',
  placement: 'bottom',
  className: 'step-6',
  mask: true,
  target: tourAttributes.TOUR_STEP_6,
};

export const videoElements = [
  'configurator',
  'my_files',
  'captures',
  'home',
  'shared_panel',
  'home_icon',
];
