import 'styles/components/mobileSettingsMenu.scss';
import React from 'react';
import { Button } from 'antd';
import logoOrganyz from 'images/svgs/logoOrganyz.svg';
import DesktopMobile from 'images/svgs/desktop-mobile.svg';
import { Translate } from '../../utility/helpers';
import settings from 'config/settings';

function MobileAppView() {
  return (
    <div className={'mobile-app-view'}>
      <div className={'logo'}>
        <img src={logoOrganyz} alt={'logo'} />
        <p>
          <Translate>register_screen_description</Translate>
        </p>
      </div>
      <div className={'mobile-view-card'}>
        <img src={DesktopMobile} alt={'desktop-mobile'} />
        <h3>
          <Translate>mobile_app</Translate>
        </h3>
        <p>
          <Translate>access_sparkbox_in_mobile</Translate>
        </p>
        <Button href={settings.DEEPLINK}>
          <Translate>open_app</Translate>
        </Button>
      </div>
    </div>
  );
}

export default MobileAppView;
