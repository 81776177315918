const actions = {
  GET_CAPTURE_LISTS: 'GET_CAPTURE_LISTS',
  GET_CAPTURE_LISTS_SUCCESS: 'GET_CAPTURE_LISTS_SUCCESS',
  GET_CAPTURE_LISTS_FAILURE: 'GET_CAPTURE_LISTS_FAILURE',
  UPDATE_CAPTURE_LISTS: 'UPDATE_CAPTURE_LISTS',
  DELETE_CAPTURE: 'DELETE_CAPTURE',
  DELETE_CAPTURE_SUCCESS: 'DELETE_CAPTURE_SUCCESS',
  DELETE_CAPTURE_FAILURE: 'DELETE_CAPTURE_FAILURE',
  UPDATE_SELECTED_CAPTURE: 'UPDATE_SELECTED_CAPTURE',
  MOVE_CAPTURE_TO_NODE: 'MOVE_CAPTURE_TO_NODE',
  MOVE_CAPTURE_TO_NODE_SUCCESS: 'MOVE_CAPTURE_TO_NODE_SUCCESS',
  MOVE_CAPTURE_TO_NODE_FAILURE: 'MOVE_CAPTURE_TO_NODE_FAILURE',
  UPDATE_DELETE_CAPTURE_MODAL: 'UPDATE_DELETE_CAPTURE_MODAL',
  UPDATE_CAPTURE_EDIT_MODAL: 'UPDATE_CAPTURE_EDIT_MODAL',
  UPDATE_CAPTURE_ITEM: 'UPDATE_CAPTURE_ITEM',
  UPDATE_CAPTURE_ITEM_SUCCESS: 'UPDATE_CAPTURE_ITEM_SUCCESS',
  UPDATE_CAPTURE_ITEM_FAILURE: 'UPDATE_CAPTURE_ITEM_FAILURE',
  SHOW_CAPTURE_DESCRIPTION_MODAL: 'SHOW_CAPTURE_DESCRIPTION_MODAL',
  UPDATE_CAPTURE_DESCRIPTION: 'UPDATE_CAPTURE_DESCRIPTION',
  ASYNC_UPDATE_CAPTURE_LISTS: 'ASYNC_UPDATE_CAPTURE_LISTS',
  ADD_SIGNATURE_TO_CAPTURE_SUCCESS: 'ADD_SIGNATURE_TO_CAPTURE_SUCCESS',
  CHANGE_CAPTURE_DRAG_WIDTH: 'CHANGE_CAPTURE_DRAG_WIDTH',
  UPDATE_CAPTURE_LISTS_FETCH_TIME: 'UPDATE_CAPTURE_LISTS_FETCH_TIME',
  ROTATE_CAPTURE_IMAGE_ANGLE: 'ROTATE_CAPTURE_IMAGE_ANGLE',
  UPDATE_CAPTURE_IMAGE_THUMBNAIL_VALUES:
    'UPDATE_CAPTURE_IMAGE_THUMBNAIL_VALUES',
  MOVE_FOLDER_ITEM_TO_CAPTURE: 'MOVE_FOLDER_ITEM_TO_CAPTURE',
  ASYNC_MOVE_FOLDER_ITEM_TO_CAPTURE: 'ASYNC_MOVE_FOLDER_ITEM_TO_CAPTURE',
  UPDATE_DELETED_CAPTURE_ITEM: 'UPDATE_DELETED_CAPTURE_ITEM',
  CAPTURE_ITEM_DOWNLOADED_SUCCESSFULLY: 'CAPTURE_ITEM_DOWNLOADED_SUCCESSFULLY',
  UPDATE_MULTIPLE_CAPTURES_ITEM_DELETE: 'UPDATE_MULTIPLE_CAPTURES_ITEM_DELETE',
  ASYNC_MOVE_BOARD_ITEM_TO_CAPTURE: 'ASYNC_MOVE_BOARD_ITEM_TO_CAPTURE',
  MOVE_NODE_TO_CAPTURES: 'MOVE_NODE_TO_CAPTURES',
  ITEM_DETAILS: 'ITEM_DETAILS',
  CAPTURE_MULTI_SELECT: 'CAPTURE_MULTI_SELECT',
  DELETE_CAPTURE_ITEM: 'DELETE_CAPTURE_ITEM',
  RESET_CAPTURE_MULTI_SELECT: 'RESET_CAPTURE_MULTI_SELECT',
  // TODO: Ticket-SP1XD-2383 - DND V2
  ADD_DRAG_ITEM_TO_CAPTURE: 'ADD_DRAG_ITEM_TO_CAPTURE',
  REMOVE_DRAG_ITEM_FROM_CAPTURE: 'REMOVE_DRAG_ITEM_FROM_CAPTURE',
  REMOVE_MULTIPLE_ITEM_FROM_CAPTURE: 'REMOVE_MULTIPLE_ITEM_FROM_CAPTURE',
  GET_CAPTURE_PAGINATION_LIST: 'GET_CAPTURE_PAGINATION_LIST',
  GET_CAPTURE_PAGINATION_LIST_SUCCESS: 'UPDATE_CAPTURE_LISTS_SUCCESS',
  GET_CAPTURE_PAGINATION_LIST_FAILURE: 'GET_CAPTURE_PAGINATION_LIST_FAILURE',
};

export default actions;
